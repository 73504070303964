import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config/config';

import AppComponent from '../AppComponent';
import AppLayout from '../layouts/AppLayout';
import ProfileForm from '../forms/ProfileForm';
import UpdatePasswordForm from '../forms/UpdatePasswordForm';
import UpdateEmailForm from '../forms/UpdateEmailForm';
import Button from '../controls/Button/Button';
import ButtonConfirmation from '../controls/ButtonConfirmation/ButtonConfirmation';
import SystemMessage from '../controls/SystemMessage/SystemMessage';
import LoadingIndicator from '../controls/LoadingIndicator/LoadingIndicator';
import { loadScript } from '../../utils/loaders';

import * as userActions from '../../actions/user';
import * as accountActions from '../../actions/account';
import * as plansActions from '../../actions/plans';

import './PageProfile.scss';

class PageProfile extends AppComponent {
  constructor(props) {
    super(props, 'page-profile');

    this.state = {
      redirectError: ''
    };

    this.stripe = null;
  }

  componentDidMount() {
    this.props.listPlans();
    loadScript('https://js.stripe.com/v3/', false, (e) => {
      this.initStripe();
    });
  }

  initStripe() {
    this.stripe = window.Stripe(
      config.stripePublicApi,
      {
        betas: ['checkout_beta_4']
      }
    );
  }

  handleStripeRedirectError (result) {
    this.setState({ redirectError: result.error.message });
  }

  selectPlan(plan) {
    this.props.changePlan(plan.Code).then(r => {
      if (!r) {
        return;
      }

      if (r.Code === -13) {
        this.stripe.redirectToCheckout({
          items: [
            {
              plan: plan.Code, 
              quantity: 1,
            }
          ],
          successUrl: `${config.appDomain}/account/profile/plan-changed`,
          cancelUrl: `${config.appDomain}/account/profile/canceled`,
          clientReferenceId: r.Data[0]
        }).then(this.handleStripeRedirectError);
      } else {
        this.props.getProfile();
      }
    });
  }

  handleClickCloseAccount(e) {
    this.props.closeAccount().then((r) => {
      if (r && r.Code === 0) {
        this.props.signOut().then((r) => {
          this.props.history.push('/good-bye');
        });
      }
    });
  }

  renderProfileForm() {
    return (
      <div className={this.e('form-container')}>
        <h3>General information</h3>
        <ProfileForm />
      </div>
    )
  }

  renderPasswordForm() {
    return (
      <div className={this.e('form-container')}>
        <h3>Update password</h3>
        <UpdatePasswordForm />
      </div>
    )
  }

  renderRedirectError() {
    if (this.state.redirectError) {
      return (
        <SystemMessage messageType='error'>
          {this.state.redirectError}
        </SystemMessage>
      )
    }
  }

  renderNoPlansAvailable() {
    if (!this.props.plans.items) {
      return (
        <SystemMessage messageType='error'>
          There are no available plans yet.
        </SystemMessage>
      )
    }
  }

  renderPlanItem(p, planCode) {
    if (p.Status !== 10 && planCode !== p.Code) {
      return;
    }

    return (
      <li key={`plan-${p.Code}`}>
        <div className={this.e('plan-card')}>
          <div className={this.e('plan-name-price')}>
            <b>{p.Name}</b>
            <span>: {p.Price} {p.Currency}/mo</span>
          </div>
          <div className={this.e('plan-desc')}>
            {p.Description}
          </div>
          <div className={this.e('plan-select')}>
            <Button
              name={p.Code === planCode ? 'Selected' : 'Select'}
              btnStyle='default'
              btnSize='small'
              onClick={(e) => { this.selectPlan(p) }}
              disabled={p.Code === planCode || p.Status !== 10}
            />
          </div>
        </div>
      </li>
    )
  }

  renderPlans () {
    if (this.props.plans.loading) {
      return (<LoadingIndicator/>);
    }

    const planCode = (this.props.account && this.props.account.profile && this.props.account.profile.Plan_Code) || '';

    return (
      <div className={this.e('plan-list')}>
        <ul>
          { this.props.plans.items.map((p) => {
            return this.renderPlanItem(p, planCode);
          }) }
        </ul>
      </div>
    )
  }

  renderChangePlan() {
    return (
      <div className={this.e('form-container')}>
        <h3>Change plan</h3>
        <p>Plans details you can find on your <a href={`${config.website}/pricing`} target='_blank' rel='noopener noreferrer'>Pricing</a> page. We will charge your card right away upon switching to paid plan.</p>
        {this.renderNoPlansAvailable()}
        {this.renderRedirectError()}
        {this.renderPlans()}
      </div>
    )
  }

  renderChangeEmail() {
    const profile = (this.props.account && this.props.account.profile) || {}
    return (
      <div className={this.e('form-container')}>
        <h3>Change account email</h3>
        <p>Your email address is {profile.Email_Confirmed ? (<span className='success'>confirmed</span>) : (<span className='danger'>not confirmed</span>)}</p>
        <UpdateEmailForm />
      </div>
    )
  }

  renderCloseAccount() {
    return (
      <div className={this.e('form-container')}>
        <h3>Account</h3>
        <p>If you do not want to use the service anymore and want to remove your account with all related data click on the button below. Please keep in mind that the operation can not be reversed.</p>
        <ButtonConfirmation 
          btnStyle='primary'
          onClick={this.handleClickCloseAccount}
          progress={this.props.account.closingAccount}
        >
          Close account
        </ButtonConfirmation>
      </div>
    )
  }

  render() {
    return (
      <AppLayout title={`Profile`} owner={this}>
        <div className={this.e('container')}>
          {this.renderProfileForm()}
          {this.renderChangeEmail()}
          {this.renderPasswordForm()}
          {this.renderChangePlan()}
          {this.renderCloseAccount()}
        </div>
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user,
    account: state.account,
    plans: state.plans
  };
  return props;
}

export default connect(mapStateToProps, { ...userActions, ...accountActions, ...plansActions })(withRouter(PageProfile));
