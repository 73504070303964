import React from 'react';

import AppComponent from '../../AppComponent';

import './SystemMessage.scss';

class SystemMessage extends AppComponent {
  constructor(props) {
    super(props, 'system-message')
  }

  render() {
    const msgType = this.props.messageType || 'error';
    const b = this.props.inline ? `inline-${msgType}` : `block-${msgType}`;

    return (
      <div className={this.b(b)}>
        <div className={this.e('text')}>
          {this.props.children ? (this.props.children) : (<div dangerouslySetInnerHTML={{ __html: this.props.message }}></div>)}
        </div>
      </div>
    );
  }
}

export default SystemMessage;
