
import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class SignInModel extends BaseModel {
  static getProps() {
    return {
      User_Login: {
        type: mprops.String,
        label: 'Login',
        default: ''
      },
      Password: {
        type: mprops.String,
        label: 'Password',
        password: true,
        default: ''
      },
      Remember_Me: {
        type: mprops.Bool,
        label: 'Remember me',
        default: false
      }
    }
  }
}
