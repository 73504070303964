import axios from 'axios';
import config from '../config/config';

export const SUBMISSIONS_TYPES = {
  SUBMISSIONS_LOADING: 'SUBMISSIONS_LOADING',
  SUBMISSIONS_SET_LIST: 'SUBMISSIONS_SET_LIST',
  SUBMISSIONS_GETTING: 'SUBMISSIONS_GETTING',
  SUBMISSIONS_GOT: 'SUBMISSIONS_GOT',
  SUBMISSIONS_REMOVING: 'SUBMISSIONS_REMOVING',
  SUBMISSIONS_REMOVED: 'SUBMISSIONS_REMOVED',
  SUBMISSIONS_LOADING_STATS: 'SUBMISSIONS_LOADING_STATS',
  SUBMISSIONS_SET_STATS: 'SUBMISSIONS_SET_STATS',
  SUBMISSIONS_STATS_RESET: 'SUBMISSIONS_STATS_RESET',
  SUBMISSIONS_STATS_DOWNLOAD: 'SUBMISSIONS_STATS_DOWNLOAD'
};

export const listSubmissions = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_LOADING, formPath, filter: cmd });
    const res = await axios.get(`${config.apiBase}/forms/${formPath}/submissions`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_SET_LIST, formPath, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_SET_LIST, formPath, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const listSubmissionsStats = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_LOADING_STATS, formPath, filter: cmd });
    const res = await axios.get(`${config.apiBase}/forms/${formPath}/submissions/stats`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_SET_STATS, formPath, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_SET_STATS, formPath, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const getSubmission = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_GETTING, formPath, id: cmd.ID, filter: cmd });
    const res = await axios.get(`${config.apiBase}/forms/${formPath}/submissions/${cmd.ID}`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_GOT, formPath, id: cmd.ID, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_GOT, formPath, id: cmd.ID, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const removeSubmission = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_REMOVING, id: cmd.ID });
    const res = await axios.delete(`${config.apiBase}/forms/${formPath}/submissions/${cmd.ID}`, { data: cmd.toSubmit() }, { withCredentials: true });
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_REMOVED, id: cmd.ID, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_REMOVED, id: cmd.ID, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const removeSubmissionsForMonth = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_STATS_RESET, id: cmd.ID, progress: true });
    const res = await axios.delete(`${config.apiBase}/forms/${formPath}/submissions/stats/${cmd.ID}`, { data: {} }, { withCredentials: true });
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_STATS_RESET, id: cmd.ID, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_STATS_RESET, id: cmd.ID, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  }
}

export const downloadSubmissionsForMonth = (formPath, cmd) => async dispatch => {
  window.open(`${config.apiBase}/forms/${formPath}/submissions/stats/${cmd.ID}/download`);
  // dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_STATS_DOWNLOAD, id: cmd.ID, progress: true });
  // const res = await axios.get(`${config.apiBase}/forms/${formPath}/submissions/stats/${cmd.ID}/download`, { params: {} }, { withCredentials: true });
  // dispatch({ type: SUBMISSIONS_TYPES.SUBMISSIONS_STATS_DOWNLOAD, id: cmd.ID, payload: res.data ? res.data : {}, progress: false });
  // return res.data ? res.data : null;
}
