import React from 'react';

import AppComponent from '../../AppComponent';
import Button from '../../controls/Button/Button';
import ButtonConfirmation from '../../controls/ButtonConfirmation/ButtonConfirmation';
import SelectInput from '../../controls/SelectInput/SelectInput';

import './ToolBar.scss';
class ToolBar extends AppComponent {
  constructor(props) {
    super(props, 'toolbar');
    this.state = {
      search: '',
    };

    this.timeId = 0;
  }

  componentDidMount() {
    this.props.buttons.forEach((b) => {
      if (b.type === 'search') {
        const search = this.getBtnPropValue(b, 'value');
        this.setState({ search });
      }
    });
  }

  handleSearchChange(e, btn, owner) {
    if (this.timeId > 0) {
      clearTimeout(this.timeId);
    }
    if (typeof btn.onSearch === 'function') {
      const self = this;
      this.timeId = setTimeout(() => { btn.onSearch(self.state.search, owner); }, 600);
    }
    this.setState({ search: e.target.value });
  }

  getBtnPropValue(btn, name) {
    return typeof btn[name] === 'function' ? btn[name](this.props.owner) : (btn[name] || '');
  }

  createBtnProps(btn) {
    return {
      name: this.getBtnPropValue(btn, 'name'),
      icon: this.getBtnPropValue(btn, 'icon'),
      title: this.getBtnPropValue(btn, 'title'),
      className: this.getBtnPropValue(btn, 'className') || 'toolbar',
      progress: this.getBtnPropValue(btn, 'progress')
    };
  }

  renderButton(btnProps, btn, idx) {
    return (
      <div key={`toolbar-btn-${idx}`} className={this.e('item')}>
        <Button
          {...btnProps}
          onClick={(e) => btn.handleClick(e, this.props.owner)}
        />
      </div>
    );
  }

  renderConfirmationButton(btnProps, btn, idx) {
    return (
      <div key={`toolbar-btn-${idx}`} className={this.e('item')}>
        <ButtonConfirmation
          {...btnProps}
          onClick={(e) => btn.handleClick(e, this.props.owner)}
        />
      </div>
    );
  }

  renderSelect(btnProps, btn, idx) {
    return (
      <div key={`toolbar-btn-${idx}`} className={this.e('item')}>
        <SelectInput
          {...btnProps}
          onChange={(e, name, value) => btn.handleChange(e, value, this.props.owner)}
          getOptionsAsync={(onDone) => btn.getOptionsAsync(onDone, this.props.owner)}
        />
      </div>
    );
  }

  renderSearch(btnProps, btn, idx) {
    return (
      <div key={`toolbar-btn-${idx}`} className={this.e('item', 'search')}>
        <input
          type='text'
          placeholder={btnProps.name}
          className={btnProps.className}
          value={this.state.search}
          onChange={(e) => this.handleSearchChange(e, btn, this.props.owner)}
        />
      </div>
    );
  }

  renderToolbarItem(btn, idx) {
    const btnProps = this.createBtnProps(btn);

    switch (btn.type) {
      default:
      case 'button':
        return this.renderButton(btnProps, btn, idx);

      case 'button-confirmation':
        return this.renderConfirmationButton(btnProps, btn, idx);

      case 'select':
        return this.renderSelect(btnProps, btn, idx);

      case 'search':
        return this.renderSearch(btnProps, btn, idx);
    }
  }

  render() {
    const buttons = this.props.buttons.map(this.renderToolbarItem).filter((b) => { return b !== null });

    return buttons.length ? (
      <div className={this.b()}>
        <div className={this.e('body')}>
          {buttons}
        </div>
      </div>
    ) : null;
  }
}

export default ToolBar;
