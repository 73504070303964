import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import AppComponent from '../../AppComponent';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Button from '../Button/Button';
import SystemMessage from '../SystemMessage/SystemMessage';
import LoadPrevOrNext from '../LoadPrevOrNext/LoadPrevOrNext';
import FileSize from '../FileSize/FileSize';
import { Table, TableRow, TableCol } from '../Table/Table';

import { BaseCmd } from '../../../utils/commands';

import * as formsActions from '../../../actions/forms';
import * as submissionsActions from '../../../actions/submissions';

import './TabStats.scss';

class TabStats extends AppComponent {
  constructor(props) {
    super(props, 'tab-stats');
  }

  componentDidMount () {
    this.loadData();
  }

  getFormPath () {
    return this.props.formPath;
  }

  getFilter () {
    return this.props.stats.filter;
  }

  loadData(filter) {
    this.props.listSubmissionsStats(this.getFormPath(), filter || this.getFilter());
  }

  handleClickReset(e, stat) {
    this.props.removeSubmissionsForMonth(this.getFormPath(), new BaseCmd({ ID: stat.ID }));
  }

  handleClickDownload(e, stat) {
    this.props.downloadSubmissionsForMonth(this.getFormPath(), new BaseCmd({ ID: stat.ID }));
  }

  renderHeader() {
    return (
      <TableRow key={`submission-header`} type='header'>
        <TableCol type='long-text'>
          Month, Year
        </TableCol>
        <TableCol type='number'>
          Total<br/>Submissions<br/>count
        </TableCol>
        <TableCol type='number'>
          Submissions<br/>Count
        </TableCol>
        <TableCol type='number'>
          Files<br/>Count
        </TableCol>
        <TableCol type='number'>
          Files<br/>Size
        </TableCol>
        <TableCol type='small-actions'>
          &nbsp;
        </TableCol>
      </TableRow>
    )
  }

  renderRow(row) {
    return (
      <TableRow key={`submission-${row.ID}`} className={this.e('row')}>
        <TableCol type='long-text'>
          <Moment format='MMMM, YYYY'>{`${row.YearMonth}Z`}</Moment>
        </TableCol>
        <TableCol type='number'>
          {row.Total_Submissions_Cnt}
        </TableCol>
        <TableCol type='number'>
          {row.Submissions_Cnt}
        </TableCol>
        <TableCol type='number'>
          {row.Files_Cnt}
        </TableCol>
        <TableCol type='number'>
          <FileSize units='mb' size={row.Files_Size} displayUnits />
        </TableCol>
        <TableCol type='small-actions'>
          {this.renderActions(row)}
        </TableCol>
      </TableRow>
    );
  }

  renderActions(row) {
    return (
      <React.Fragment>
        <Button 
          icon='cloud-download'
          btnStyle='action'
          onClick={(e) => this.handleClickDownload(e, row)} 
          progress={this.props.downloadingId === row.ID}
          title="Download a .CSV file with data for the period."
        />
        <Button 
          icon='trash'
          btnStyle='action'
          onClick={(e) => this.handleClickReset(e, row)} 
          progress={this.props.stats.resetingId === row.ID}
          title="Remove submissions for given period. Total statistics will remain the same."
        />
      </React.Fragment>
    );
  }

  renderStatsList() {
    const { stats } = this.props;
    if (stats.filter.firstPageLoading()) {
      return (<LoadingIndicator/>);
    }

    if (!stats.items.length) {
      return (
        <div className={this.e('no-items')}>
          <SystemMessage messageType='info'>
            There is no submissions yet for the selected form.
          </SystemMessage>
        </div>
      )
    }

    return (
      <div>
        <div className='responsive'>
          <Table>
            {this.renderHeader()}
            {stats.items.map((row) => { return this.renderRow(row) })}
          </Table>
        </div>
        <LoadPrevOrNext
          loadPrev={false}
          allowRefresh
          load={this.loadData}
          getFilter={this.getFilter}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={this.b()}>
        {this.renderStatsList()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    stats: state.stats
  };

  return props;
}

export default connect(mapStateToProps, {...formsActions, ...submissionsActions})(withRouter(TabStats));
