import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import flattenSitemap from '../utils/flattenSitemap';
import AppComponent from './AppComponent';
import * as Pages from './pages/index';

import './App.scss';

class App extends AppComponent {
  constructor(props) {
    super(props, 'app');
  }

  renderRoutes() {
    return flattenSitemap().map(sm => {
      if (!sm.url && !sm.component !== undefined) {
        return null;
      }

      const path = sm.urlmask || sm.url;
      const exact = sm.urlmask === undefined || sm.urlmask.length === 0 ? true : false;

      return (
        <Route key={sm.url} exact={exact} path={path} component={sm.component} />
      );
    });
  }

  render() {
    return (
      <BrowserRouter>
        <div className={this.b()}>
          <Switch>
            {this.renderRoutes()}
            <Route component={Pages.PageNotFound} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
