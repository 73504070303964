import React from 'react';

import AppComponent from '../../AppComponent';

import config from '../../../config/config';

import './AppFooter.scss';

class AppFooter extends AppComponent {
  constructor(props) {
    super(props, 'footer')
  }

  render() {
    return (
      <footer className={this.b()}>
        <div className={this.e('container')}>
          <div>&copy; 2019 {config.appName}.</div>
          <div className='space-top-delimeter text-muted'>Should you have any concerns, questions or ideas feel free to reach out to us: support@formsubmits.com</div>
        </div>
      </footer>
    );
  }
}

export default AppFooter;
