import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../../AppComponent';
import Nav from '../Nav/Nav';
import AppLogo from '../AppLogo/AppLogo';

import * as actions from '../../../actions/user';

import './Header.scss';

class Header extends AppComponent {
  constructor(props) {
    super(props, 'header');
  }

  handleClickSignOut () {
    this.props.signOut().then((r) => {
      this.props.history.push('/signin');
    });
  }

  renderLogo() {
    return (
      <div className={this.e('logo')}>
        <AppLogo className={this.e('header-logo')} />
      </div>
    );
  }

  renderNav() {
    return (
      <div className={this.e('nav')}>
        <Nav />
      </div>
    )
  }

  renderUser() {
    if (!this.props.user.auth) {
      return null;
    }

    const userLogin = (this.props.user.auth && this.props.user.auth.User_Login) || '...';
    return (
      <div className={this.e('user-info')}>
        <div className={this.e('user-login')}>
          {userLogin}
        </div>
        <div className={this.e('user-signout')}>
          <a href='#signout' onClick={this.handleClickSignOut}>Sign out</a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <header className={this.b()}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          {this.renderNav()}
          {this.renderUser()}
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(Header));
