import React from 'react';

import AppComponent from '../../AppComponent';

import './TabControlPage.scss';

export default class TabControlPage extends AppComponent {
  constructor(props) {
    super(props, 'tab-control-page');
  }
  render() {
    return (
      <div className={this.b()} id={this.props.id}>
        {this.props.children}
      </div>
    )
  }
}
