import { FIELDS_TYPES } from '../actions/fields';
import { LIST_MODES, ListCmd } from '../utils/commands';

export default function(state = null, action) {
  if (state == null) {
    state = {
      loading: true,
      creating: false,
      saving: '',
      removing: '',
      items: [],
      filter: new ListCmd(),
      errors: null,
      edit: null
    }
  }

  switch (action.type)
  {
    case FIELDS_TYPES.FIELDS_LOADING:
      return {
        ...state,
        loading: true,
        filter: new ListCmd(action.filter).startLoading()
      };
          
    case FIELDS_TYPES.FIELDS_SET_LIST: {
      let newItems = action.payload.Data;
      
      const newFilter = new ListCmd(action.filter).setLoadedCnt(newItems.length);
    
      switch (newFilter.listMode) {
        default: break;
        case LIST_MODES.ADD_AFTER: newItems = [...state.items, ...newItems]; break;
        case LIST_MODES.ADD_BEFORE: newItems = [...newItems, ...state.items]; break;
        case LIST_MODES.REPLACE: newItems = [...newItems]; break;
      }

      return {
        ...state,
        loading: false,
        items: newItems,
        filter: newFilter,
        errors: action.payload && action.payload.Errors
      };
    }

    case FIELDS_TYPES.FIELDS_CREATING: {
      return {...state, creating: true };
    }

    case FIELDS_TYPES.FIELDS_ADD: {
      return {
        ...state,
        creating: false,
        items: [
          ...action.payload.Data || [],
          ...state.items
        ],
        errors: action.payload && action.payload.Errors
      };
    }

    case FIELDS_TYPES.FIELDS_REMOVING: {
      return {...state, removing: action.id };
    }

    case FIELDS_TYPES.FIELDS_REMOVE: {
      return {...state, removing: '', items: [...state.items.filter((f) => { return f.Name !== action.id })] };
    }

    case FIELDS_TYPES.FIELDS_SAVING: {
      return {...state, saving: action.id };
    }

    case FIELDS_TYPES.FIELDS_SET: {
      if (action.payload && action.payload.Code === 0) {
        const newField = (action.payload && action.payload.Data && action.payload.Data[0]) || {};
        const newItems = state.items.map((f) => {
          if (f.Name === action.id) {
            f = newField;
          }
          return f;
        });
      
        return {
          ...state,
          saving: '',
          items: newItems 
        };
      } else {
        return {
          ...state,
          saving: ''
        };
      }
    }

    default:
      return state;
  }
}
