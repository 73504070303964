import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import AppComponent from '../../AppComponent';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Button from '../Button/Button';
import ToolBar from '../ToolBar/ToolBar';
import SystemMessage from '../SystemMessage/SystemMessage';
import LoadPrevOrNext from '../LoadPrevOrNext/LoadPrevOrNext';
import FileSize from '../FileSize/FileSize';
import { limitString } from '../../../utils/stringHelpers';
import { Table, TableRow, TableCol } from '../Table/Table';

import { BaseCmd, ListCmd, LIST_MODES } from '../../../utils/commands';

import * as fieldsActions from '../../../actions/fields';
import * as submissionsActions from '../../../actions/submissions';

import './TabSubmissions.scss';

const TOOLBAR = [
  {
    name: 'Refresh',
    icon: 'refresh',
    handleClick: (event, owner) => {
      owner.loadData(owner.getFilter().setListMode(LIST_MODES.REPLACE).resetPage());
    }
  },
  {
    type: 'search',
    name: 'Search',
    icon: 'search',
    value: (owner) => {
      return owner.props.submissions.filter.Filter || '';
    },
    onSearch: (keyword, owner) => {
      const newFilter = owner.getFilter({ Filter: keyword }).setListMode(LIST_MODES.REPLACE).resetPage();
      owner.loadData(newFilter);
    }
  }
]

class TabSubmissions extends AppComponent {
  constructor(props) {
    super(props, 'tab-submissions');
    this.state = {
      fields: {},
      tbVersion: 0,
    };

    this.timeId = 0;
  }

  componentDidMount () {
    this.props.listFields(this.props.formPath, new BaseCmd({ Path: this.props.formPath })).then((r) => {
      if (!r || r.Code !== 0) {
        return;
      }
      if (this.props.formPath !== this.props.submissions.formPath) {
        this.loadData(this.getFilter().setListMode(LIST_MODES.REPLACE).reset());
        this.setState({ tbVersion: this.state.tbVersion + 1 });
      }
      this.setFields(r.Data);
    });
  }

  getFilter (params) {
    return new ListCmd({ ...this.props.submissions.filter, ...params });
  }

  loadData(filter) {
    const newFilter = filter ? filter : this.getFilter();
    this.props.listSubmissions(this.props.formPath, newFilter);
  }

  setFields (fields) {
    const obj = {};
    fields.forEach((f) => {
      obj[f.Name] = f;
    });
    this.setState({ fields: obj });
  }

  handleClickView(e, submission) {
    this.props.history.push(`/forms/${this.props.formPath}/submissions/${submission.ID}/view`);
  }

  handleClickRemove(e, submission) {
    const cmd = new BaseCmd({ ID: submission.ID });
    this.props.removeSubmission(this.props.formPath, cmd);
  }

   renderFormDataCol(submission) {
    const json = JSON.parse(submission.Form_Data) || {};
    const items = Object.keys(json).map(c => {
      const label = (this.state.fields[c] && this.state.fields[c].Label) || c;
      return (
        <div key={`form-data-${submission.ID}-${c}`} className={this.e('row')}>
          <div className={this.e('col', 'label')}>{label}</div>
          <div className={this.e('col', 'value')}>
            {limitString(json[c], 128)}
          </div>
        </div>
      );
    });

    return (
      <React.Fragment>
        <div key={`form-data-${submission.ID}-request-id`} className={this.e('row')}>
          <div className={this.e('col', 'label')}>Request ID</div>
          <div className={this.e('col', 'value')}>
            {submission.Request_ID}
          </div>
        </div>
        {items}
      </React.Fragment>
    );
  }

  renderHeader() {
    return (
      <TableRow key={`submission-header`} type='header'>
        <TableCol type='datetime'>
          Submission<br/>date/time
        </TableCol>
        <TableCol type='long-text'>
          Form Data
        </TableCol>
        <TableCol type='number'>
          Files<br/>Count
        </TableCol>
        <TableCol type='number'>
          Files<br/>Size
        </TableCol>
        <TableCol type='small-actions'>
          &nbsp;
        </TableCol>
      </TableRow>
    )
  }

  renderRow(row) {
    return (
      <TableRow key={`submission-${row.ID}`}>
        <TableCol type={['datetime', 'top']}>
          <div className={this.e('date')}>
            <Moment format='MMM DD, YYYY'>{`${row.Submission_DTTM}Z`}</Moment>
          </div>
          <div className={this.e('time')}>
            <Moment format='hh:mm a'>{`${row.Submission_DTTM}Z`}</Moment>
          </div>
        </TableCol>
        <TableCol type={['long-text', 'top']}>
          {this.renderFormDataCol(row)}
        </TableCol>
        <TableCol type={['number', 'top']}>
          {row.Files_Cnt}
        </TableCol>
        <TableCol type={['number', 'top']}>
          <FileSize units='mb' size={row.Files_Size} displayUnits />
        </TableCol>
        <TableCol type={['small-actions', 'top']}>
          {this.renderActions(row)}
        </TableCol>
      </TableRow>
    );
  }

  renderActions(row) {
    return (
      <React.Fragment>
        <Button
          icon='eye-open'
          btnStyle='action'
          onClick={(e) => this.handleClickView(e, row)}
          title='View submission details'
        />
        <Button 
          icon='trash'
          btnStyle='action'
          onClick={(e) => this.handleClickRemove(e, row)} 
          progress={this.props.submissions.removing === row.ID}
        />
      </React.Fragment>
    )
  }

  renderSubmissionsList() {
    const { submissions } = this.props;
    if (submissions.filter.firstPageLoading()) {
      return (<LoadingIndicator/>);
    }

    if (!submissions.items.length) {
      return (
        <div className={this.e('no-items')}>
          <SystemMessage messageType='info'>
            There is no submissions yet for the selected form.
          </SystemMessage>
        </div>
      )
    }

    return (
      <div>
        <div className='responsive'>
          <Table>
            {this.renderHeader()}
            {submissions.items.map((row) => { return this.renderRow(row) })}
          </Table>
        </div>
        <LoadPrevOrNext
          loadPrev={false}
          allowRefresh
          load={this.loadData}
          getFilter={this.getFilter}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={this.b()}>
        <ToolBar buttons={TOOLBAR} owner={this} key={`tb-sbm-${this.state.tbVersion}`} />
        {this.renderSubmissionsList()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    fields: state.fields,
    submissions: state.submissions
  };
  return props;
}

export default connect(mapStateToProps, {...fieldsActions, ...submissionsActions})(withRouter(TabSubmissions));
