import siteMap from '../config/siteMap';

export default function() {
    
    const fs = [];
    
    siteMap.forEach(s => {

        fs.push({
            parent: null,
            name: s.name,
            url: s.url,
            urlmask: s.urlmask,
            component: s.component,
            items: s.items || [],
            visible: s.visible !== undefined ? s.visible : true,
            showGoBack: s.showGoBack !== undefined ? s.showGoBack : false,
            level: 1
        });

        if (!s.items)
            return;

        s.items.forEach(s2 => {
            fs.push({
                parent: s,
                name: s2.name,
                url: s2.url,
                urlmask: s2.urlmask,
                component: s2.component,
                items: s2.items || [],
                visible: s2.visible !== undefined ? s2.visible : true,
                showGoBack: s2.showGoBack !== undefined ? s2.showGoBack : false,
                level: 2
            });

            if (!s2.items)
                return;

            s2.items.forEach(s3 => {
                fs.push({
                    parent: s2,
                    page: s3.name,
                    url: s3.url,
                    urlmask: s3.urlmask,
                    component: s3.component,
                    items: [],
                    visible: s3.visible !== undefined ? s3.visible : true,
                    showGoBack: s3.showGoBack !== undefined ? s3.showGoBack : false,
                    level: 3
                });    
            });

        });
    });

    return fs;
}