import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';
import SystemMessage from '../controls/SystemMessage/SystemMessage';
import AppLogo from '../controls/AppLogo/AppLogo';

import { getSummaryErrorsText, BaseCmd } from '../../utils/commands';

import * as actions from '../../actions/account';

import './PageConfirmEmail.scss';

class PageConfirmEmail extends AppComponent {
  constructor(props) {
    super(props, 'page-confirm-email');
    this.state = {
      emailConfirming: false,
      token: ''
    }
  }

  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    if (search.token) {
      this.props.confirmEmail(new BaseCmd({ Token: search.token })).then((r) => {
        this.setState({ emailConfirming: false });
      });
    }
    this.setState({ emailConfirming: search.token ? true : false, token: search.token });
  }

  isError() {
    return !this.state.token || this.props.account.errors;
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Email confirmation</h1>
      </div>
    )
  }

  renderEmailConfirmed() {
    return (
      <div className={this.e('intro')}>
        Your email has been confirmed. Thank you.
      </div>
    )
  }

  renderEmailConfirming() {
    return (
      <div className={this.e('intro')}>
        Your email is being confirmed. Please wait ...
      </div>
    )
  }

  renderNoToken() {
    return (
      <SystemMessage messageType='error'>
        The link to confirm your email is incorrect. Please request it one more time.
      </SystemMessage>
    )
  }

  renderError() {
    if (!this.props.account.errors) {
      return null;
    }

    return (
      <SystemMessage messageType='error'>
        {getSummaryErrorsText({ Errors: this.props.account.errors })}
      </SystemMessage>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Email confirmation`} owner={this}>
        <div className={this.e('container')}>
          { this.renderLogo() }
          { !this.state.token && this.renderNoToken() }
          { !this.state.emailConfirming && !this.isError() && this.renderEmailConfirmed() }
          { this.state.emailConfirming && !this.isError() && this.renderEmailConfirming() }
          { this.renderError() }
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    account: state.account
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageConfirmEmail));
