import React from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';
import SignInForm from '../forms/SignInForm';
import AppLogo from '../controls/AppLogo/AppLogo';

import * as actions from '../../actions/forms';

import './PageSignIn.scss';

class PageSignIn extends AppComponent {
  constructor(props) {
    super(props, 'page-signin');
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Sign In</h1>
      </div>
    )
  }

  renderSignInForm() {
    return (
      <div className={this.e('form-container')}>
        <SignInForm />
      </div>
    )
  }

  renerNoAccount() {
    return (
      <div className={this.e('no-account')}>
        <div>
          <span>Have no account yet? Please </span>
          <Link className='nav-link' to='/signup'>Sign Up.</Link>
        </div>
        <div>
          <Link className='nav-link' to='/forgot-password'>Forgot password</Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Sign In`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          {this.renderSignInForm()}
          {this.renerNoAccount()}
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageSignIn));
