import React from 'react';

import AppComponent from '../../AppComponent';
import Button from '../Button/Button';

import './ButtonConfirmation.scss';

class ButtonConfirmation extends AppComponent {
  constructor(props) {
    super(props, 'confirming-buttons')
    this.state = {
      confirming: false
    };
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ confirming: true });
  }

  handleClickNo(e) {
    e.preventDefault();
    this.setState({ confirming: false });
  }

  handleClickYes(e) {
    this.setState({ confirming: false });
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e);
    }
  }

  render() {

    if (!this.state.confirming) {
      return (
        <Button {...this.props} onClick={this.handleClick}>
          {this.props.children}
        </Button>
      );
    } 
    else 
    {
      return (
        <div className={this.b()}>
          <Button onClick={this.handleClickYes} btnStyle='primary' icon='ok'>
            Confirm
          </Button>
          <Button onClick={this.handleClickNo} btnStyle='secondary' icon='ban-circle'>
            Cancel
          </Button>
        </div>
      );
    }
    
  }
}

export default ButtonConfirmation;
