import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import Button from '../controls/Button/Button';
import { BaseCmd } from '../../utils/commands';
import UpdateEmailModel from './../../models/UpdateEmail';

import * as actions from '../../actions/account';

class UpdateEmailForm extends AppComponent {
  constructor(props) {
    super(props, 'form-update-email');
    this.state = {
      formFields: new UpdateEmailModel(),
      needConfirmation: false,
      confirmationSent: false
    }
  }

  componentWillReceiveProps(nextProps) {
    const profile = (nextProps.account && nextProps.account.profile) || {}
    if (profile.Email !== this.state.formFields.Email) {
      this.setState({ formFields: new UpdateEmailModel({ Email: profile.Email }), needConfirmation: !profile.Email_Confirmed });
    }
  }

  handleSubmit (values, onDone) {
    this.setState({ formFields: new UpdateEmailModel(values) });
    this.props.updateEmail(new BaseCmd({ ...values })).then((r) => {
      this.setState({ needConfirmation: true });
      onDone(r);
    });
  }

  handleEmailChange(name, value) {
    const profile = (this.props.account && this.props.account.profile) || {};

    this.setState({ 
      needConfirmation: profile.Email === value ? !profile.Email_Confirmed : false,
      formFields: new UpdateEmailModel({...this.state.formFields, [name]: value })
    });

    if (typeof this.props.onFormChange1 === 'function') {
      this.props.onFormChange(name, value);
    }
  }

  handleClickConfirmEmail(e) {
    e.preventDefault();
    this.props.requestEmailConfirmation().then((r) => {
      if (r && r.Code === 0) {
        this.setState({ confirmationSent: true });
      }
    });
  }

  renderExtraButtons() {
    if (!this.state.needConfirmation) {
      return null;
    }

    if (this.state.confirmationSent) {
      const profile = (this.props.account && this.props.account.profile) || {}
      return (
        <div>
          A confirmation has been sent to your email '{profile.Email}'
        </div>
      )
    }

    return (
      <div>
        <Button
          type='button'
          btnSize='small'
          btnStyle='secondary'
          onClick={this.handleClickConfirmEmail}
        >
          Confirm email
        </Button>
      </div>
    )
  }

  render () {
    return (
      <SimpleForm 
        btnName='Update email'
        form={this.state.formFields}
        onSubmit={this.handleSubmit}
        hideCancel
        progress={this.props.account.savingPwd}
        onFormChange={this.handleEmailChange}
        renderExtraButtons={this.renderExtraButtons}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user,
    account: state.account
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(UpdateEmailForm));
