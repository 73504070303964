import React from 'react';
import { CSSTransition } from "react-css-transition";

import AppComponent from '../../AppComponent';

import './Table.scss';

class Table extends AppComponent {
  constructor(props) {
    super(props, 'table');
  }

  render() {
    return (
      <div className={this.b()}>
        {this.props.children}
      </div>
    );
  }
}

class TableRow extends AppComponent {
  constructor(props) {
    super(props, 'table');
  }

  render() {
    return (
      <CSSTransition
        transitionAppear
        defaultClassName={this.e('row-default')}
        enterClassName={this.e('row-enter')}
        leaveClassName={this.e('row-leave')}
        activeClassName={this.e('row-active')}
        active
      >
        <div className={this.e('row', this.props.type)}>
          {this.props.children}
        </div>
      </CSSTransition>      
    );
  }
}

class TableCol extends AppComponent {
  constructor(props) {
    super(props, 'table');
  }

  render() {
    return (
      <div className={this.e('col', this.props.type)}>
        {this.props.children}
      </div>
    );
  }
}

export {
  Table,
  TableRow,
  TableCol,
}
