import axios from 'axios';
import config from '../config/config';

export const PLAN_TYPES = {
  PLAN_PROGRESS: 'PLAN_PROGRESS',
  PLAN_ON_RESULT: 'PLAN_ON_RESULT',
  PLAN_LOADING: 'PLAN_LOADING',
  PLAN_LOADED: 'PLAN_LOADED'
};

export const listPlans = () => async dispatch => {
  try {
    dispatch({ type: PLAN_TYPES.PLAN_LOADING });
    const res = await axios.get(`${config.apiBase}/plans`, { params: {}, withCredentials: true });
    dispatch({ type: PLAN_TYPES.PLAN_LOADED, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: PLAN_TYPES.PLAN_LOADED, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const selectPlan = (cmd) => async dispatch => {
  try {
    dispatch({ type: PLAN_TYPES.PLAN_PROGRESS });
    const res = await axios.post(`${config.apiBase}/plans/${cmd.ID}`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: PLAN_TYPES.PLAN_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: PLAN_TYPES.PLAN_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}
