import { FORMS_TYPES } from '../actions/forms';
import { LIST_MODES, ListCmd } from '../utils/commands';

export default function(state = null, action) {
  if (state == null) {
    state = {
      loading: true,
      creating: true,
      saving: '',
      removing: '',
      publishing: '',
      items: [],
      filter: new ListCmd(),
      errors: null,
      edit: null
    }
  }

  const errCode = (action.payload && action.payload.Code) || 0; 
  const newErrors = errCode === 0 ? null : action.payload && action.payload.Errors;

  switch (action.type)
  {
    case FORMS_TYPES.FORMS_LOADING: {
      const newFilter = new ListCmd(action.filter).startLoading();
      return {
        ...state,
        loading: true,
        filter: newFilter
      };
    }
          
    case FORMS_TYPES.FORMS_SET_LIST: {
      let newItems = action.payload.Data;
      
      const newFilter = new ListCmd(action.filter).setLoadedCnt(newItems.length);
    
      switch (newFilter.listMode) {
        default: break;
        case LIST_MODES.ADD_AFTER: newItems = [...state.items, ...newItems]; break;
        case LIST_MODES.ADD_BEFORE: newItems = [...newItems, ...state.items]; break;
        case LIST_MODES.REPLACE: newItems = [...newItems]; break;
      }

      return {
        ...state,
        loading: false,
        items: newItems,
        filter: newFilter,
        errors: newErrors
      };
    }

    case FORMS_TYPES.FORMS_CREATING: {
      return {
        ...state,
        creating: true,
        errors: newErrors
      };
    }

    case FORMS_TYPES.FORMS_ADD: {
      return {
        ...state,
        creating: false,
        items: [
          ...action.payload.Data || [],
          ...state.items
        ],
        errors: newErrors
      };
    }

    case FORMS_TYPES.FORMS_REMOVING: {
      return {...state, removing: action.id };
    }

    case FORMS_TYPES.FORMS_REMOVE: {
      return {...state, removing: '', items: [...state.items.filter((f) => { return f.Path !== action.id })] };
    }

    case FORMS_TYPES.FORMS_PUBLISHING: {
      return {...state, publishing: action.id };
    }

    case FORMS_TYPES.FORMS_PUBLISHED: {
      const newItems = state.items.map((f) => {
        if (f.Path === action.id) {
          f.Published_On = action.payload && action.payload.Data && action.payload.Data[0];
          f.Pending_Changes = false;
        }
        return f;
      });
      return {
        ...state,
        publishing: '',
        items: newItems,
        errors: newErrors
      };
    }

    case FORMS_TYPES.FORMS_GETTING: {
      return {
        ...state,
        loading: true,
        edit: null
      };
    }

    case FORMS_TYPES.FORMS_SET:
    case FORMS_TYPES.FORMS_GOT: {
      return {
        ...state, 
        loading: false,
        edit: action.payload && action.payload.Data && action.payload.Data[0],
        errors: newErrors
      };
    }

    default:
      return state;
  }
}
