import { ACC_TYPES } from '../actions/account';

export default function(state = null, action) {

  if (state == null) {
    state = {
      loading: true,
      saving: false,
      savingPwd: false,
      confirmingEmail: false,
      rqEmailConfirmation: false,
      closingAccount: false,
      profile: null,
      errors: null
    }
  }

  const errCode = (action.payload && action.payload.Code) || 0; 
  const newErrors = errCode === 0 ? null : action.payload && action.payload.Errors;

	switch (action.type)
	{
		case ACC_TYPES.ACC_PROFILE_LOADING:
      return {...state, loading: true };
            
    case ACC_TYPES.ACC_PROFILE_SAVING:
      return {...state, loading: false, saving: true };
      
    case ACC_TYPES.ACC_PROFILE_SET:
			return {
        ...state,
        loading: false,
        saving: false,
        profile: action.payload.Data[0],
        errors: newErrors
      };

    case ACC_TYPES.ACC_PROFILE_PASSWORD:
      return {
        ...state,
        savingPwd: action.progress,
        errors: newErrors
      }

    case ACC_TYPES.ACC_PROFILE_CNF_EMAIL:
        return {
          ...state,
          confirmingEmail: action.progress,
          errors: newErrors
        }

    case ACC_TYPES.ACC_PROFILE_RQ_EMAIL_CNF:
        return {
          ...state,
          rqEmailConfirmation: action.progress,
          errors: newErrors
        }

    case ACC_TYPES.ACC_PROFILE_CLOSE:
      return {
        ...state,
        closingAccount: action.progress,
        errors: newErrors
      }

		default:
			return state;
	}
}
