import React from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import AppLogo from '../controls/AppLogo/AppLogo';

import * as actions from '../../actions/account';

import './PageForgotPassword.scss';

class PageForgotPassword extends AppComponent {
  constructor(props) {
    super(props, 'page-forgot-password');
    this.state = {
      requestSent: false
    }
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  handleSubmitted () {
    this.setState({ requestSent: true });
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Forgot password?</h1>
      </div>
    )
  }

  renderLinkSent() {
    return (
      <div className={this.e('intro')}>
        <p>An email with the link to reset password has been sent to your email address.</p>
        <p>If you do not see the email in your inbox folder in 2 minutes please check spam/junk/updates folders.</p>
      </div>
    )
  }

  renderIntro() {
    return (
      <div className={this.e('intro')}>
        Please enter your user login. We will send you an email with the link to reset your password to the email associated with the login.
      </div>
    )
  }

  renderForm() {
    return (
      <div className={this.e('form-container')}>
        <ForgotPasswordForm
          onSubmitted={this.handleSubmitted}
        />
      </div>
    )
  }

  renderAccountLinks() {
    return (
      <div className={this.e('account-links')}>
        <Link className='nav-link' to='/signin'>Sign In</Link>
        <span>&nbsp;|&nbsp;</span>
        <Link className='nav-link' to='/signup'>Sign Up.</Link>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Forgot Password`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          { this.state.requestSent && (
            <div>
              {this.renderLinkSent()}
            </div>
          )}
          { !this.state.requestSent && (
            <div>
              {this.renderIntro()}
              {this.renderForm()}
            </div>
          )}
          {this.renderAccountLinks()}
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageForgotPassword));
