import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../../AppComponent';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import ToolBar from '../ToolBar/ToolBar';
import { Table, TableRow, TableCol } from '../Table/Table';

import { ListCmd, BaseCmd } from '../../../utils/commands';
import FieldModel from '../../../models/FieldModel';
import FieldEditForm from '../../forms/FieldEditForm';

import * as formsActions from '../../../actions/forms';
import * as fieldsActions from '../../../actions/fields';

import './TabFields.scss';

const TOOLBAR_FIELDS = [
  {
    name: 'Add field',
    icon: 'plus',
    handleClick: (e, owner) => {
      const fieldModel = new FieldModel();
      owner.props.createField(owner.props.formPath, new BaseCmd(fieldModel)).then(r => {
        if (r && r.Code === 0) {
          owner.setState({ fieldInEdit: r.Data[0].Name, });
        }
      });
    },
    progress: (owner) => {
      return owner.props.fields.creating;
    }
  },
  {
    name: 'Apply changes',
    icon: 'cloud-upload',
    handleClick: (e, owner) => {
      owner.props.publishForm(new BaseCmd({ Path: owner.props.formPath }));
    },
    progress: (owner) => {
      return owner.props.forms.publishing !== '';
    }
  },
];
class TabFields extends AppComponent {
  constructor(props) {
    super(props, 'tab-fields');
    this.state = {
      fieldInEdit: ''
    };
  }

  componentDidMount () {
    this.props.listFields(this.props.formPath, new ListCmd());
  }

  handleFieldsSubmitted (values) {
    this.setState({ fieldInEdit: '' });
  }

  handleClickEditField (e, field) {
    this.setState({ fieldInEdit: field.Name });
  }

  handleFieldEditCancel () {
    this.setState({ fieldInEdit: '' });
  }

  handleClickRemoveField (e, field) {
    this.props.removeField(this.props.formPath, new BaseCmd({ Name: field.Name }));
  }

  renderType(field) {
    const props = FieldModel.getProps();
    const fieldType = props.Field_Type.options.filter((t) => { return t.value === field.Field_Type })[0];
    return fieldType && fieldType.text;
  }

  renderFieldForm (field) {
    return (
      <div className={this.e('form-container')}>
        <FieldEditForm
          formPath={this.props.formPath}
          formFields={field}
          onSubmitted={this.handleFieldsSubmitted}
          onCancel={this.handleFieldEditCancel}
        />
      </div>
    )
  }

  renderNoFields () {
    return (
      <div className={this.e('description')}>
        <p>You do not have form fields configuration yet. If you want to validate submitted data you need to add and configure form fields. Otherwise, you can publish form as is and start collecting submissions.</p>
        <p>As soon as you add fields, the service will process the information related to the fields and all other data will be ignored.</p>
        <p>Do not forget to apply changes to the form after you have made added or modified fields.</p>
      </div>
    )
  }

  renderFieldsHeader() {
    const { fields } = this.props;

    if (fields.loading || fields.items.length === 0) {
      return null;
    }

    return (
      <Table>
        <TableRow type='header'>
          <TableCol type='small-icon'>
            &nbsp;
          </TableCol>
          <TableCol type='short-text'>
            Field Name
          </TableCol>
          <TableCol type='long-text'>
            Label
          </TableCol>
          <TableCol type='short-text'>
            Type
          </TableCol>
          <TableCol type='short-text'>
            Is Required
          </TableCol>
          <TableCol type='small-actions'>
            &nbsp;
          </TableCol>
        </TableRow>
      </Table>
    );
  }

  renderFieldFormContainer(f, idx) {
    return (
      <Table key={`field-${idx}`} className={this.e('row-container')}>
        <TableRow>
          <TableCol type={['small-icon', 'top']}>
            <Icon type='italic' />
          </TableCol>
          <TableCol type='long-text'>
            {this.renderFieldForm(f)}
          </TableCol>
        </TableRow>
      </Table>
    );
  }

  renderFieldRow(f, idx, fields) {
    return (
      <Table key={`field-${idx}`}>
        <TableRow>
          <TableCol type='small-icon'>
            <Icon type='italic' />
          </TableCol>
          <TableCol type='short-text'>
            {f.Name}
          </TableCol>
          <TableCol type='long-text'>
            {f.Label}
          </TableCol>
          <TableCol type='short-text'>
            {this.renderType(f)}
          </TableCol>
          <TableCol type='short-text'>
            {f.Required ? 'Required' : 'Not required'}
          </TableCol>
          <TableCol type='small-actions'>
            <div className={this.e('actions')}>
              <Button
                icon='pencil'
                btnStyle='action'
                onClick={(e) => this.handleClickEditField(e, f)}
              />
              <Button 
                icon='trash'
                btnStyle='action'
                onClick={(e) => this.handleClickRemoveField(e, f)} 
                progress={fields.removing === f.Name}
              />
            </div>
          </TableCol>
        </TableRow>
      </Table>
    );
  }

  renderFields() {
    const { fields } = this.props;
    
    if (fields.loading) {
      return (<LoadingIndicator />);
    }

    if (fields.items.length === 0) {
      return this.renderNoFields();
    }

    const fieldsItems = fields.items.map((f, idx) => {
      return (this.state.fieldInEdit === f.Name) ? 
        this.renderFieldFormContainer(f, idx) : 
        this.renderFieldRow(f, idx, fields);
    })

    return (
      <div className={this.e('fields-list')}>
        {fieldsItems}
      </div>
    );
  }

  render() {
    return (
      <div className={this.b()}>
        <ToolBar buttons={TOOLBAR_FIELDS} owner={this} />
        <div className='responsive'>
          {this.renderFieldsHeader()}
          {this.renderFields()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    forms: state.forms,
    fields: state.fields
  };
  return props;
}

export default connect(mapStateToProps, {...formsActions, ...fieldsActions })(withRouter(TabFields));
