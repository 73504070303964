import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import config from '../../config/config';

import AppComponent from '../AppComponent';
import AppLayout from '../layouts/AppLayout';
import LoadingIndicator from '../controls/LoadingIndicator/LoadingIndicator';
import Button from '../controls/Button/Button';
import SystemMessage from '../controls/SystemMessage/SystemMessage';
import { Table, TableRow, TableCol } from '../controls/Table/Table';
import FileSize from '../controls/FileSize/FileSize';

import { ListCmd, BaseCmd } from '../../utils/commands';

import * as fieldsActions from '../../actions/fields';
import * as submissionsActions from '../../actions/submissions';
import * as filesActions from '../../actions/files';

import './PageSubmissionView.scss';

class PageSubmissionView extends AppComponent {
  constructor(props) {
    super(props, 'page-submission');
    this.state = {
      formPath: '',
      submissionId: ''
    };
  }

  componentDidMount () {
    const { formPath, submissionId } = this.props.match.params;
    // this.props.listForms(this.props.forms.filter);
    this.props.clearFilesList();
    this.props.listFields(formPath, new ListCmd());
    this.props.getSubmission(formPath, new BaseCmd({ ID: submissionId })).then(r => {
      if (r && r.Code === 0 && r.Data[0].Files_Cnt > 0) {
        this.props.listSubmissionFiles(formPath, submissionId, this.props.files.filter);
      }
    });
  }

  handleClickBack () {
    const { formPath } = this.props.match.params;
    this.props.history.push(`/forms/${formPath}/submissions`);
  }

  processValue(key, value) {
    switch (key) {
      case 'Submission_DTTM':
        return <Moment format='MMM DD, YYYY hh:mm a'>{`${value}Z`}</Moment>;

      default:
        return <span>{value}</span>;
    }
  }

  renderMainDetails(sbm) {
    const mainDataMap = {
      Submission_DTTM: 'Date/time:',
      Request_ID: 'Request ID',
      Remote_IP: 'Remote IP',
      User_Agent: 'User Agent',
      Referrer: 'Referrer',
    };

    const mainData = Object.keys(mainDataMap).map((k) => {
      return (
        <div key={`detail-main-${k}`} className={this.e('detail')}>
          <div className={this.e('col', 'name')}>
            {mainDataMap[k]}
          </div>
          <div className={this.e('col', 'value')}>
            {this.processValue(k, sbm[k])}
          </div>
        </div>
      )
    });

    return (
      <div className={this.e('block')}>
        <h3>General data</h3>
        {mainData}
      </div>
    )
  }

  renderFormData(sbm) {
    if (!sbm.Form_Data) {
      return (
        <SystemMessage messageType='info'>There is no form data available</SystemMessage>
      )
    }

    const data = JSON.parse(sbm.Form_Data);
    const mapLabels = {};
    
    if (this.props.fields.items && this.props.fields.items.length > 0) {
      this.props.fields.items.forEach((f) => {
        if ([10000, 10102, 10103].indexOf(f.Field_Type) !== -1) {
          return;
        }
        mapLabels[f.Name] = f.Label || f.Name;
      });
    } else {
      Object.keys(data).forEach((f) => {
        mapLabels[f] = f;
      });
    }

    const formData = Object.keys(mapLabels).map((k) => {
      const value = (<span>{data[k]}</span>);
      return (
        <div key={`detail-main-${k}`} className={this.e('detail')}>
          <div className={this.e('col', 'name')}>
            {mapLabels[k]}
          </div>
          <div className={this.e('col', 'value')}>
            {value}
          </div>
        </div>
      )
    });

    return (
      <div className={this.e('block')}>
        <h3>Form data</h3>
        {formData}
      </div>
    );
  }

  handleClickDownloadFile(e, file) {
    e.preventDefault();
    const { formPath, submissionId } = this.props.match.params;
    const url = `${config.apiBase}/forms/${formPath}/submissions/${submissionId}/files/${file.ID}`;
    window.open(url);
  }

  handleClickRemoveFile(e, file) {
    e.preventDefault();
    const { formPath, submissionId } = this.props.match.params;
    this.props.removeSubmissionFile(formPath, submissionId, file.ID);
  }

  renderFilesTableHeader() {
    return (
      <TableRow key={`file-header`} type='header'>
        <TableCol type='long-text'>File Name</TableCol>
        <TableCol type='number'>File Size</TableCol>
        <TableCol type='short-actions'>&nbsp;</TableCol>
      </TableRow>
    );
  }

  renderFiles() {
    const { files } = this.props;
    if (files.loading || files.items.length === 0) {
      return null;
    }

    const items = files.items.map(f => {
      return (
        <TableRow key={`file-${f.ID}`}>
          <TableCol type='long-text'>{f.File_Name}</TableCol>
          <TableCol type='number'>
            <FileSize units='mb' size={f.File_Size} displayUnits />
          </TableCol>
          <TableCol type='short-actions'>
            <Button 
              icon='cloud-download'
              btnStyle='action'
              onClick={(e) => this.handleClickDownloadFile(e, f)}
            />
            <Button 
              icon='trash'
              btnStyle='action'
              onClick={(e) => this.handleClickRemoveFile(e, f)}
              progress={this.props.files.removingId === f.ID}
            />
          </TableCol>
        </TableRow>
      );
    });

    return (
      <div className={this.e('block')}>
        <h3>Files</h3>
        <div className='responsive'>
          <Table className={this.e('files-container')}>
            {this.renderFilesTableHeader()}
            {items}
          </Table>
        </div>
      </div>
    );
  }

  renderDetails() {
    const { submissions } = this.props;
    if (submissions.loading) {
      return (<LoadingIndicator/>);
    }

    if (!submissions.edit) {
      return (
        <div className={this.e('no-items')}>
          <SystemMessage messageType='info'>
            No submission information has been loaded. Please reload the page.
          </SystemMessage>
        </div>
      )
    }

    const sbm = submissions.edit;

    return (
      <div className={this.e('details')}>
        {this.renderMainDetails(sbm)}
        {this.renderFormData(sbm)}
        {this.renderFiles()}
        <div>
          <Button onClick={this.handleClickBack} btnSize='bigger' btnStyle='secondary'>
            Back to list
          </Button>
        </div>
      </div>
    )
    
  }

  render() {
    return (
      <AppLayout title={`Submission details`} owner={this}>
        <div className={this.b()}>
          {this.renderDetails()}
        </div>
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    fields: state.fields,
    files: state.files,
    submissions: state.submissions
  };
  return props;
}

export default connect(mapStateToProps, {...fieldsActions, ...submissionsActions, ...filesActions})(withRouter(PageSubmissionView));
