
export const ModelPropsTypes = {
  String: 0,
  RichText: 1,
  Number: 2,
  Bool: 3,
  Guid: 4,
  Date: 5,
  Time: 6,
  DateTime: 7,
  Select: 10,
  Object: 100,
  Array: 200
};

export default class BaseModel {
  static getProps() {
    return {};
  }

  constructor(data) {
    this.fill(data);
  }

  getProp(name) {
    const p = this.constructor.getProps();
    return p[name];
  }

  getValues() {
    const values = {};
    const props = this.constructor.getProps();
    Object.keys(props).forEach((key) => {
      values[key] = this[key] !== undefined ? this[key] : props[key].default;
    });
    return values;
  }

  fill(data) {
    if (!data) {
      const props = this.constructor.getProps();
      Object.keys(props).forEach((key) => {
        this[key] = props[key].default;
      });
      return;
    }

    const props = this.constructor.getProps()

    Object.keys(props).forEach((key) => {
      this[key] = data[key] !== undefined ? data[key] : props[key].default;
    });
  }
}
