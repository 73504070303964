import React from 'react';

import './LoadingIndicator.scss';

const LoadingIndicator = () => {
    return (
      <div className='loading-indicator'>
        <div className='loading-indicator__indicator '>
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
        <div className='loading-indicator__text'>
          Loading data ...
        </div>
      </div>
    );
}

export default LoadingIndicator;