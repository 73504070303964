import { SUBMISSIONS_TYPES } from '../actions/submissions';
import { LIST_MODES, ListCmd } from '../utils/commands';

export default function(state = null, action = {}) {
  if (state == null) {
    state = {
      formPath: '',
      loading: true,
      items: [],
      resetingId: '',
      filter: new ListCmd({ PageSize: 50 }),
      errors: null,
      edit: null
    }
  }

  switch (action.type)
  {
    case SUBMISSIONS_TYPES.SUBMISSIONS_LOADING: {
      const newFilter = new ListCmd(action.filter).startLoading();
      return {
        ...state,
        formPath: action.formPath,
        loading: true,
        filter: newFilter
      };
    }
          
    case SUBMISSIONS_TYPES.SUBMISSIONS_SET_LIST: {
      let newItems = action.payload.Data;

      const newFilter = new ListCmd(action.filter).setLoadedCnt(newItems.length);
    
      switch (newFilter.listMode) {
        default: break;
        case LIST_MODES.ADD_AFTER: newItems = [...state.items, ...newItems]; break;
        case LIST_MODES.ADD_BEFORE: newItems = [...newItems, ...state.items]; break;
        case LIST_MODES.REPLACE: newItems = [...newItems]; break;
      }

      return {
        ...state,
        formPath: action.formPath,
        loading: false,
        items: newItems,
        filter: newFilter,
        errors: action.payload && action.payload.Errors
      };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_GETTING: {
      return {...state, formPath: action.formPath, loading: true };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_GOT: {
      return {
        ...state,
        formPath: action.formPath,
        loading: false,
        edit: action.payload && action.payload.Data && action.payload.Data[0],
        errors: action.payload && action.payload.Errors
      };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_REMOVING: {
      return {...state, removing: action.id };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_REMOVED: {
      return {...state, removing: '', items: [...state.items.filter((f) => { return f.ID !== action.id })] };
    }

    default:
      return state;
  }
}
