import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

const minMaxValuesVisible = [0, 10, 50, 60, 65, 80, 90, 1000];

export default class FieldModel extends BaseModel {
  static getProps() {
    return {
      Current_Name: {
        type: mprops.String,
        disableEdit: true,
      },
      Name: {
        type: mprops.String,
        label: 'Name',
        hint: 'Use the name in "name" attribute of &lt;input&gt; tag of your form.',
        default: '',
      },
      Field_Type: {
        type: mprops.Select,
        label: 'Type',
        default: 0,
        options: [
          { value: 0, text: 'Text' },
          { value: 10, text: 'Number' },
          { value: 20, text: 'Date' },
          { value: 30, text: 'Time' },
          { value: 40, text: 'DateTime' },
          { value: 50, text: 'Phone' },
          { value: 60, text: 'Email' },
          { value: 65, text: 'Url' },
          { value: 70, text: 'Boolean' },
          { value: 80, text: 'Match to Reg. Expression' },
          { value: 90, text: 'Select' },
          // { value: 100, text: 'Long text' },
          { value: 1000, text: 'File' },
          { value: 10000, text: 'Antispam' },
          { value: 10102, text: 'ReCaptcha v2' },
          // { value: 10103, text: 'ReCaptcha v3' },
        ],
      },
      Label: {
        type: mprops.String,
        label: 'Label',
        hint: 'The label will be returned as a part of a validation error description.',
        default: '',
      },
      Required: {
        type: mprops.Bool,
        label: 'The field must have a value',
        hint: '',
        default: false,
      },
      Min_Value: {
        type: mprops.Number,
        label: 'Min. value',
        hint: 'For text types, it is the minimum allowed length. For number type, it is the minimum allowed value. For file type, this is min allowed file size in bytes.',
        visible: (values) => {
          return minMaxValuesVisible.indexOf(+values.Field_Type) !== -1;
        },
      },
      Max_Value: {
        type: mprops.Number,
        label: 'Max. value',
        hint: 'For text types, it is the maximum allowed length. For number type, it is the maximum allowed value. For file type, this is max allowed file size in bytes. No more than 20971520 bytes.',
        visible: (values) => {
          return minMaxValuesVisible.indexOf(+values.Field_Type) !== -1;
        },
      },
      Options: {
        type: mprops.String,
        label: 'Options',
        hint: 'Either regular expression (ex: ^([0-9]{10})$) for the "Match to Reg. Expression" type or coma separated list of allowed values (case insensitive) for "Select" field type.',
        default: '',
        visible: (values) => {
          return [80, 90, 1000, 10000, 10102].indexOf(+values.Field_Type) !== -1;
        },
      },
      Updated_On: {
        type: mprops.String,
        disableEdit: true,
      }
    }
  }
}
