import React from 'react';

import AppComponent from '../../AppComponent';
import Button from '../../controls/Button/Button';

import { LIST_MODES } from '../../../utils/commands';

import './LoadPrevOrNext.scss';

class LoadPrevOrNext extends AppComponent {
  constructor(props) {
    super(props, 'lml');

    this.txtLoading = (props && props.txtLoading) || 'Loading ...';
    this.txtLoadMore = (props && props.txtLoadMore) || 'Load more';
    this.txtRefresh = (props && props.txtRefresh) || 'Refresh';
    this.allowRefresh = (props && props.allowRefresh) || false;
    this.loadPrev = (props && props.loadPrev) || false;
  }

  getFilter() {
    return this.props.getFilter();
  }

  handleClickRefresh(e) {
    this.props.load(this.getFilter().setListMode(LIST_MODES.REPLACE).resetPage());
  }

  handleLoadNext(e) {
    if (this.loadPrev) {
      this.props.load(this.getFilter().setListMode(LIST_MODES.ADD_BEFORE).prevPage().startLoading());
    } else {
      this.props.load(this.getFilter().setListMode(LIST_MODES.ADD_AFTER).nextPage().startLoading());
    }
  }

  renderRefreshButton () {
    const f = this.getFilter();

    if (!this.allowRefresh || f.loading) {
      return null;
    }

    return (
      <Button
        btnStyle='secondary'
        btnSize='small'
        onClick={this.handleClickRefresh}
        disabled={f.loading}
      >
        {f.loading ? (<span>{this.txtLoading}</span>) : (<span>{this.txtRefresh}</span>)}
      </Button>
    )
  }

  render() {
    const f = this.getFilter();

    if (!f) {
      return null
    }

    const fn = this.loadPrev ? f.canLoadPrev() : f.canLoadNext();

    if (!fn) {
      if (!f.loading && f.Page > 1) {
        return (
          <div className={this.e('load-more')}>
            <p>All items are already loaded</p>
            {this.renderRefreshButton()}
          </div>
        );
      }

      return this.renderRefreshButton();
    }

    return (
      <div className={this.e('load-more')}>
        <Button
          btnStyle='secondary'
          btnSize='small'
          onClick={this.handleLoadNext}
          disabled={f.loading}
        >
          {f.loading ? (<span>{this.txtLoading}</span>) : (<span>{this.txtLoadMore}</span>)}
        </Button>
        {this.renderRefreshButton()}
      </div>
    )
  }
}

export default LoadPrevOrNext;
