import React from 'react'
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import AppLogo from '../controls/AppLogo/AppLogo';
import EmptyLayout from '../layouts/EmptyLayout';

import './PageChangePlanSuccess.scss';

class PageChangePlanSuccess extends AppComponent {
  constructor(props) {
    super(props, 'page-change-plan-success');
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Plan Change</h1>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Plan changed`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          <div>
            <p>You plan will be changed within several minutes. Thank you.</p>
            <p>It is recommended to sign out and sign in again.</p>
            <p><Link to='/account/profile'>Go to 'Profile' page</Link></p>
          </div>
        </div>
      </EmptyLayout>
    )
  }
}

export default withRouter(PageChangePlanSuccess);
