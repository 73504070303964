
import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class UpdateModel extends BaseModel {
  static getProps() {
    return {
      User_Login: {
        type: mprops.String,
        default: '',
        disableEdit: true,
      },
      Password: {
        type: mprops.String,
        label: 'Password',
        password: true,
        default: ''
      },
      Repeat_Password: {
        type: mprops.String,
        label: 'Repeat password',
        password: true,
        default: ''
      }
    }
  }
}
