import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../../AppComponent';
import Button from '../../controls/Button/Button';
import flattenSitemap from '../../../utils/flattenSitemap';
// import findPageByUrl from '../../../utils/findPageByUrl';

import * as actions from '../../../actions/user';

import './Nav.scss';

class Nav extends AppComponent {
  constructor(props) {
    super(props, 'nav');

    this.state = {
      toggled: false
    }
  }

  handleClickToggle(e) {
    e.preventDefault();
    this.setState({ toggled: !this.state.toggled });
  }

  handleClickSignOut () {
    this.props.signOut().then((r) => {
      this.props.history.push('/signin');
    });
  }

  render() {
    const items = flattenSitemap().filter((i) => {
      return i.visible;
    }).map((l) => {
      const active = ''; //page && l.url === page.url ? 'active' : '';
      return (
        <li key={`bav-link-${l.url}`}><Link className={this.e('nav-link', active)} to={l.url}>{l.name}</Link></li>
      )
    });

    const login = (this.props.user.auth && this.props.user.auth.User_Login) || '';
    const signOutName = `Sign out (${login})`;

    return (
      <nav className={this.b()}>
        <Button 
          className={this.e('toggle')} 
          btnStyle='link'
          icon='menu-hamburger' 
          onClick={this.handleClickToggle}
        />
        <ul className={this.e('items', this.state.toggled ? 'toggled' : '')}>
          {items}
          <li className={this.e('mobile')}>
            <Link className={this.e('nav-link')} to='#signou' onClick={this.handleClickSignOut}>{signOutName}</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(Nav));
