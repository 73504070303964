import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions/user';
import AppComponent from '../AppComponent';

// import 'normalize.css';
import '../../styles/glyphicons.css';
import '../../styles/index.scss';
import './EmptyLayout.scss';

class EmptyLayout extends AppComponent {
  constructor(props) {
    super(props, 'empty-layout')
  }

  render() {
    return (
      <div className={this.b()}>
        <div className={this.e('container')}>
          <div className={this.e('content')}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(EmptyLayout));
