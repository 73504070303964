import { PLAN_TYPES } from '../actions/plans';

export default function(state = null, action) {
  if (state == null) {
    state = {
      loading: true,
      items: [],
      errors: null
    }
  }

  switch (action.type)
  {
    case PLAN_TYPES.PLAN_LOADING:
      return {...state, loading: true };
          
    case PLAN_TYPES.PLAN_LOADED: {
      return {
        ...state,
        loading: false,
        items: action.payload && action.payload.Data,
        errors: action.payload && action.payload.Errors
      };
    }

    default:
      return state;
  }
}
