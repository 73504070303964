import React from 'react';

import AppComponent from '../../AppComponent';

import './Icon.scss';

class Icon extends AppComponent {
  constructor(props) {
    super(props, 'icon');

    this.type = props.type || 'search';
    this.customType = props.customType || '';
  }

  getIconSrc(customType) {
    const baseUrl = 'https://pl.azureedge.net/plc'
    switch (customType) {
      default: return '';
      case 'calendar-google': return `${baseUrl}/calendar_google.png`;
      case 'calendar-yahoo': return `${baseUrl}/calendar_yahoo.png`;
      case 'calendar-ics': return `${baseUrl}/calendar_ics.png`;
      case 'facebook': return `${baseUrl}/icon-social-facebook.png`;
      case 'twitter': return `${baseUrl}/icon-social-twitter.png`;
      case 'linkedin': return `${baseUrl}/icon-social-linkedin.png`;
      case 'email': return `${baseUrl}/icon-social-mail.png`;
      case 'print': return `${baseUrl}/icon-social-print.png`;
      case 'share': return `${baseUrl}/icon-social-share.png`;
    }
  }

  render() {
    if (this.type === 'custom') {
      return (
        <div className={this.b(this.props.noMargin ? 'no-margin' : '')}>
          <img className={this.e('image')} src={this.getIconSrc(this.customType)} alt='' />
        </div>
      )
    }

    return (
      <div className={this.b(this.props.noMargin ? 'no-margin' : '')}>
        <span className={`glyphicon glyphicon-${this.type}`} aria-hidden='true'></span>
      </div>
    );
  }
}

export default Icon;
