import { FILES_TYPES } from '../actions/files';
import { ListCmd } from '../utils/commands';

export default function(state = null, action) {
  if (state == null) {
    state = {
      loading: true,
      items: [],
      removingId: '',
      filter: new ListCmd({ PageSize: 25 }),
      errors: null
    }
  }

  switch (action.type)
  {
    case FILES_TYPES.FILES_LIST: {
      if (action.progress) {
        return {
          ...state,
          loading: true,
          filter: new ListCmd(action.filter).startLoading()
        };  
      } else {
        let newItems = action.payload.Data || [];
        return {
          ...state,
          loading: false,
          items: newItems,
          filter: new ListCmd(action.filter).setLoadedCnt(newItems.length)
        };
      }
    }
          
    case FILES_TYPES.FILES_REMOVE: {
      if (action.progress) {
        return {
          ...state,
          removingId: action.id
        };
      } else {
        return {
          ...state,
          removingId: '',
          items: [...state.items.filter((f) => { return f.ID !== action.id })]
        };
      }
    }

    default:
      return state;
  }
}
