export default {
  appName: 'FormSubmits',
  appDomain: 'http://app.formsubmits.com',
  website: 'https://formsubmits.com',
  submissionApiBase: 'https://api.formsubmits.com/forms',
  apiBase: 'https://api.formsubmits.com/api',
  stripePublicApi: 'pk_live_SUyQXzi8U2vHEJuQQwfHcnxl',
};

// export default {
//   appName: 'FormSubmits',
//   appDomain: 'http://app.dev.formsubmits.com:3000',
//   website: 'https://formsubmits.com',
//   submissionApiBase: 'https://api.formsubmits.com/forms',
//   apiBase: 'https://api.formsubmits.com/api',
//   //apiBase: 'http://localhost:65342/api'
//   stripePublicApi: 'pk_test_ZBjpEWiqJsSvdrGhp8PmuWgE',
// };
