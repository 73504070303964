import { combineReducers } from 'redux';
// import { reducer as reduxForm } from 'redux-form';
import userReducer from './user';
import accountReducer from './account';
import plansReducer from './plans';
import formsReducer from './forms';
import fieldsReducer from './fields';
import submissionsReducer from './submissions';
import filesReducer from './files';
import statsReducer from './stats';

export default combineReducers({
    user: userReducer,
    account: accountReducer,
    plans: plansReducer,
    forms: formsReducer,
    fields: fieldsReducer,
    submissions: submissionsReducer,
    files: filesReducer,
    stats: statsReducer
})
