import React from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';
import * as actions from '../../actions/forms';
import SignUpForm from '../forms/SignUpForm';
import AppLogo from '../controls/AppLogo/AppLogo';

import './PageSignIn.scss';

class PageSignUp extends AppComponent {
  constructor(props) {
    super(props, 'page-signin');
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Sign Up</h1>
      </div>
    )
  }

  renderSignUpForm() {
    return (
      <div className={this.e('form-container')}>
        <SignUpForm />
      </div>
    )
  }

  renerHaveAccount() {
    return (
      <div className={this.e('no-account')}>
        <span>You aleady have an account? </span>
        <Link className='nav-link' to='/signin'>Please Sign in.</Link>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Sign Up`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          {this.renderSignUpForm()}
          {this.renerHaveAccount()}
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageSignUp));
