import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config/config';

import AppComponent from '../AppComponent';
import AppLayout from '../layouts/AppLayout';
import LoadingIndicator from '../controls/LoadingIndicator/LoadingIndicator';
import Button from '../controls/Button/Button';

import FormEditForm from '../forms/FormEditForm';
import TabContol from '../controls/TabControl/TabControl';
import TabControlPage from '../controls/TabControl/TabControlPage';
import TabSubmissions from '../controls/TabSubmissions/TabSubmissions';
import TabStats from '../controls/TabStats/TabStats';
import TabFields from '../controls/TabFields/TabFields';

import { BaseCmd } from '../../utils/commands';

import * as formActions from '../../actions/forms';

import './PageFormsEdit.scss';

class PageFormsEdit extends AppComponent {
  constructor(props) {
    super(props, 'form-edit-page');
    this.state = {
      fieldInEdit: ''
    };
  }

  getFormPath () {
    return this.props.match && this.props.match.params && this.props.match.params.formPath;
  }

  getFormTab () {
    return this.props.match && this.props.match.params && this.props.match.params.tabId;
  }

  componentDidMount () {
    this.props.getForm(new BaseCmd({ Path: this.getFormPath() }));
  }

  handleClickBack (e) {
    this.props.history.push('/forms');
  }

  handleSubmitted (data) {
  }

  renderEditFormForm() {
    const { forms } = this.props;

    return forms.loading ? 
      (<LoadingIndicator />) :
      (
        <div className={this.e('form-container')}>
          <FormEditForm
            onSubmitted={this.handleSubmitted}
          />
        </div>
      );
  }  

  renderFormEndpoint () {
    return (
      <div className={this.e('form-endpoint')}>
        <div>
          Use the URL below in the <b>action</b> attribute of your <b>&lt;form&gt;</b> tag. The <b>method</b> attribute must be set to <b>'POST'</b>.
        </div>
        <div className='space-top-delimeter'>
          <input type='text' className={this.e('entry-point')} value={`${config.submissionApiBase}/${this.getFormPath()}/submit`} readOnly />
          {/* <span className='url'>{`${config.submissionApiBase}/${this.getFormPath()}/submit`}</span> */}
        </div>
      </div>
    )
  }

  renderBackButton () {
    return (
      <div className={'back'}>
        <Button onClick={this.handleClickBack} btnSize='bigger' btnStyle='secondary'>
          Back to forms
        </Button>
      </div>
    )
  }

  renderPageTitle() {
    if (!this.props.forms.edit) {
      return '...';
    }

    return this.props.forms.edit.Name;
  }

  handleTabClick (tabId) {
    this.props.history.replace(`/forms/${this.getFormPath()}/${tabId}`);
  }

  render() {
    const activeTabId = this.getFormTab();
    return (
      <AppLayout title={this.renderPageTitle()} owner={this}>
        <TabContol onTabClick={this.handleTabClick}>
          <TabControlPage tabName='Submissions' id='submissions' active={activeTabId === 'submissions'}>
            <TabSubmissions formPath={this.getFormPath()} />
          </TabControlPage>
          <TabControlPage tabName='Stats' id='stats'  active={activeTabId === 'stats'}>
            <TabStats formPath={this.getFormPath()} />
          </TabControlPage>
          <TabControlPage tabName='Settings' id='settings' active={activeTabId === 'settings'}>
            {this.renderEditFormForm()}
            {this.renderFormEndpoint()}
          </TabControlPage>
          <TabControlPage tabName='Fields' id='fields' active={activeTabId === 'fields'}>
            <TabFields formPath={this.getFormPath()} />
          </TabControlPage>
        </TabContol>
        {this.renderBackButton()}
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    forms: state.forms
  };
  return props;
}

export default connect(mapStateToProps, { ...formActions })(withRouter(PageFormsEdit));
