import axios from 'axios';
import config from '../config/config';

export const ACC_TYPES = {
  ACC_PROGRESS: 'ACC_PROGRESS',
  ACC_ON_RESULT: 'ACC_ON_RESULT',
  ACC_PROFILE_LOADING: 'ACC_PROFILE_LOADING',
  ACC_PROFILE_SAVING: 'ACC_PROFILE_SAVING',
  ACC_PROFILE_SET: 'ACC_PROFILE_SET',
  ACC_PROFILE_PASSWORD: 'ACC_PROFILE_PASSWORD',
  ACC_PROFILE_EMAIL: 'ACC_PROFILE_EMAIL',
  ACC_PROFILE_RQ_EMAIL_CNF: 'ACC_PROFILE_RQ_EMAIL_CNF',
  ACC_PROFILE_CNF_EMAIL: 'ACC_PROFILE_CNF_EMAIL',
  ACC_PROFILE_CLOSE: 'ACC_PROFILE_CLOSE',
  ACC_CHANGE_PLAN: 'ACC_CHANGE_PLAN'
};

export const updateEmail = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_EMAIL, progress: true });
    const res = await axios.post(`${config.apiBase}/account/updateemail`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_EMAIL, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_EMAIL, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}

export const requestEmailConfirmation = () => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_RQ_EMAIL_CNF, progress: true });
    const res = await axios.post(`${config.apiBase}/account/requestemailconfirmation`, {}, { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_RQ_EMAIL_CNF, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_RQ_EMAIL_CNF, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}

export const confirmEmail = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CNF_EMAIL, progress: true });
    const res = await axios.post(`${config.apiBase}/account/confirmemail`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CNF_EMAIL, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CNF_EMAIL, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}

export const updatePasswod = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_PASSWORD, progress: true });
    const res = await axios.post(`${config.apiBase}/account/updatepassword`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_PASSWORD, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_PASSWORD, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}

export const requestPasswordReset = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROGRESS });
    const res = await axios.post(`${config.apiBase}/account/requestpasswordreset`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const resetPassword = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROGRESS });
    const res = await axios.post(`${config.apiBase}/account/resetpassword`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_ON_RESULT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const getProfile = () => async dispatch => {
  dispatch({ type: ACC_TYPES.ACC_PROFILE_LOADING });
  const res = await axios.get(`${config.apiBase}/account/profile`, { params: {}, withCredentials: true });
  dispatch({ type: ACC_TYPES.ACC_PROFILE_SET, payload: res.data ? res.data : {} });
  return res.data ? res.data : null;
}

export const saveProfile = (cmd) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_SAVING });
    const res = await axios.post(`${config.apiBase}/account/profile`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_SET, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_SAVING, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const closeAccount = () => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CLOSE, progress: true });
    const res = await axios.post(`${config.apiBase}/account/close`, {}, { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CLOSE, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_PROFILE_CLOSE, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}

export const changePlan = (planCode) => async dispatch => {
  try {
    dispatch({ type: ACC_TYPES.ACC_CHANGE_PLAN, progress: true });
    const res = await axios.post(`${config.apiBase}/account/changePlanTo/${planCode}`, {}, { withCredentials: true });
    dispatch({ type: ACC_TYPES.ACC_CHANGE_PLAN, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: ACC_TYPES.ACC_CHANGE_PLAN, payload: res.data ? res.data : null, progress: false });
    return res.data ? res.data : null;
  }
}