import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class FormModel extends BaseModel {
  static getProps() {
    return {
      Path: {
        type: mprops.String,
        disableEdit: true,
        default: '',
      },
      Name: {
        type: mprops.String,
        label: 'Name',
        hint: 'This name is for display purposes only.',
        required: true,
        default: '',
      },
      Description: {
        type: mprops.RichText,
        label: 'Description',
        hint: 'Use the field for the form description which is used only inside the app.',
      },
      Subject: {
        type: mprops.String,
        label: 'Subject',
        hint: 'This is the subject text that will be used in email notifications if configured.',
        default: 'Form submission: {{Request_ID}}',
      },
      Thank_You_Message: {
        type: mprops.RichText,
        label: 'Thank you message',
        hint: 'This is the message our service displays to the user after successful form submission.',
        default: '',
      },
      Redirect_Url: {
        type: mprops.String,
        label: 'Redirect to URL',
        hint: 'Enter a URL of the page where to redirect the user after successful submission. If no URL provided the user will be redirected to our "Thank you" page. If you submit form data via AJAX request, the URL will be sent in the system response.',
        placeholder: 'http://',
        default: '',
      },
      Submit_To_Emails: {
        type: mprops.String,
        label: 'Submit to email',
        hint: 'An email address where to forward form submissions.',
        default: '',
      },
      Submit_To_Url: {
        type: mprops.String,
        label: 'Submit to URL',
        hint: 'Enter URL of your service that will receive submitted data via POST requests. <b>The feature is available in paid plans only</b>.',
        placeholder: 'http://',
        default: '',
      },
      Allowed_Referers: {
        type: mprops.String,
        label: 'Allowed referrers',
        hint: 'If it is empty there are no restrictions for the source of data. You can enter several sources separated by a comma. Examples: www.mywebsite.com',
        default: '',
      },
      Publish: {
        type: mprops.Bool,
        label: 'Apply changes (publish the form) after saving them',
        default: true,
      },
      Updated_On: {
        type: mprops.String,
        disableEdit: true,
      }
    }
  }
}
