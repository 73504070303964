import axios from 'axios';
import config from '../config/config';

export const FORMS_TYPES = {
  FORMS_CREATING: 'FORMS_CREATING',
  FORMS_LOADING: 'FORMS_LOADING',
  FORMS_SET: 'FORMS_SET',
  FORMS_SET_LIST: 'FORMS_SET_LIST',
  FORMS_ADD: 'FORMS_ADD',
  FORMS_SAVING: 'FORMS_SAVING',
  FORMS_REMOVING: 'FORMS_REMOVING',
  FORMS_REMOVE: 'FORMS_REMOVE',
  FORMS_PUBLISHING: 'FORMS_PUBLISHING',
  FORMS_PUBLISHED: 'FORMS_PUBLISHED',
  FORMS_GETTING: 'FORMS_GETTING',
  FORMS_GOT: 'FORMS_GOT',
  FORMS_SUBMISSIONS_REMOVE: 'FORMS_SUBMISSIONS_REMOVE'
};

export const listForms = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_LOADING, filter: cmd });
    const res = await axios.get(`${config.apiBase}/forms`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_SET_LIST, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_SET_LIST, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const getForm = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_GETTING, id: cmd.Path, filter: cmd });
    const res = await axios.get(`${config.apiBase}/forms/${cmd.Path}`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_GOT, id: cmd.Path, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_GOT, id: cmd.Path, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const createForm = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_CREATING });
    const res = await axios.post(`${config.apiBase}/forms`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_ADD, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_ADD, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const updateForm = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_SAVING, id: cmd.Path });
    const res = await axios.put(`${config.apiBase}/forms/${cmd.Path}`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_SET, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_SET, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const publishForm = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_PUBLISHING, id: cmd.Path });
    const res = await axios.post(`${config.apiBase}/forms/${cmd.Path}/publish`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_PUBLISHED, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_PUBLISHED, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const removeForm = (cmd) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_REMOVING, id: cmd.Path });
    const res = await axios.delete(`${config.apiBase}/forms/${cmd.Path}`, { data: cmd.toSubmit() }, { withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_REMOVE, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_REMOVE, id: cmd.Path, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const removeSubmissions = (formPath) => async dispatch => {
  try {
    dispatch({ type: FORMS_TYPES.FORMS_SUBMISSIONS_REMOVE, id: formPath, progress: true });
    const res = await axios.delete(`${config.apiBase}/forms/${formPath}/submissions`, { data: {} }, { withCredentials: true });
    dispatch({ type: FORMS_TYPES.FORMS_SUBMISSIONS_REMOVE, id: formPath, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FORMS_TYPES.FORMS_SUBMISSIONS_REMOVE, id: formPath, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  }
}
