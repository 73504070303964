import React from 'react'
import { CSSTransition } from "react-css-transition";

import AppComponent from '../../AppComponent';

import TabControlPage from './TabControlPage';

import './TabControl.scss';

CSSTransition.childContextTypes = {
  // this can be empty
}


export default class TabControl extends AppComponent {
  constructor(props) {
    super(props, 'tab-control');
    this.state = {
      activeTabId: '',
    }
  }

  componentDidMount() {
    if (!this.props.children) {
      return;
    }

    let activeTabId = this.props.children[0].props.id;

    this.props.children.forEach(t => {
      if (t.props.active === true) {
        activeTabId = t.props.id;
      }
    });

    this.setState({ activeTabId });
  }

  onTabClick(event, tab) {
    event.preventDefault();

    if (typeof this.props.onTabClick === 'function') {
      this.props.onTabClick(tab.props.id);
    }

    this.setState({ activeTabId: tab.props.id });
  }

  ActiveTabM (t) {
    return {
      active: this.state.activeTabId === t.props.id,
    };
  }

  renderTabHeaderItem(t) {
    return (
      <li key={t.props.id} className={this.e('tab-item', this.ActiveTabM(t))}>
        <a href={`#${t.props.id}`} onClick={(e) => this.onTabClick(e, t)}>
          {t.props.tabName}
        </a>
      </li>
    );
  }

  renderTabsHeader() {
    return (
      <div className={this.e('header-container')}>
        <ul className={this.e('header')}>
            {this.props.children.map(this.renderTabHeaderItem)}
        </ul>
      </div>
    )
  }

  renderTabsContent() {
    return this.props.children.map(t => {
      return (
        <CSSTransition key={`tab-${t.props.id}`}
            transitionAppear
            defaultClassName={this.e('default')}
            enterClassName={this.e('enter')}
            leaveClassName={this.e('leave')}
            activeClassName={this.e('active')}
            active={t.props.id === this.state.activeTabId}
          >
            <TabControlPage {...t}>
              {t.props.children}
            </TabControlPage>
          </CSSTransition>
      );
    });
  }

  // renderTabsContent() {
  //   const activeTab = this.props.children.filter(t => t.props.id === this.state.activeTabId)[0];
    
  //   if (activeTab) {
  //     return (
  //       <TabControlPage {...activeTab}>{activeTab.props.children}</TabControlPage>
  //     );
  //   }
  // }

  render() {
    return (
      <div className={this.b()}>
        {this.renderTabsHeader()}
        <div className={this.e('panels')}>
          {this.renderTabsContent()}
        </div>
      </div>
    )
  }
}
