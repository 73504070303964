
import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class UpdateEmailModel extends BaseModel {
  static getProps() {
    return {
      Email: {
        type: mprops.String,
        default: ''
      },
      Email_Confirmed: {
        type: mprops.Bool,
        hint: '',
        default: false,
        disableEdit: true,
      }
    }
  }
}
