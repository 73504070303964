import * as Pages from '../components/pages/index';

export default [
  {
    name: 'Home',
    url: '/',
    component: Pages.PageHome,
    visible: false,
    items: [],
  },
  {
    name: 'Forms',
    url: '/forms',
    component: Pages.PageForms,
    items: [
      {
        name: 'Edit form',
        visible: false,
        url: '/forms/:formPath/:tabId',
        component: Pages.PageFormsEdit,
      },
      {
        name: 'Submission details',
        visible: false,
        url: '/forms/:formPath/submissions/:submissionId/view',
        component: Pages.PageSubmissionView,
      },
    ]
  },
  {
    name: 'Profile',
    url: '/account/profile',
    component: Pages.PageProfile,
    items: [],
  },
  {
    name: 'Sign In',
    visible: false,
    url: '/signin',
    component: Pages.PageSignIn,
  },
  {
    name: 'Sign Up',
    visible: false,
    url: '/signup',
    component: Pages.PageSignUp,
  },
  {
    name: 'Forgot Password',
    visible: false,
    url: '/forgot-password',
    component: Pages.PageForgotPassword,
  },
  {
    name: 'Reset Password',
    visible: false,
    url: '/reset-password',
    component: Pages.PageResetPassword,
  },
  {
    name: 'Email confirmation',
    visible: false,
    url: '/confirm-email',
    component: Pages.PageConfirmEmail,
  },
  {
    name: 'Good bye',
    visible: false,
    url: '/good-bye',
    component: Pages.PageGoodBye,
  },
  {
    name: 'Plan changed',
    visible: false,
    url: '/account/profile/plan-changed',
    component: Pages.PageChangePlanSuccess,
  }
]
