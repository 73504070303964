import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import AppLayout from '../layouts/AppLayout';
import LoadingIndicator from '../controls/LoadingIndicator/LoadingIndicator';
import Button from '../controls/Button/Button';
import ButtonConfirmation from '../controls/ButtonConfirmation/ButtonConfirmation';
import Icon from '../controls/Icon/Icon';
import ToolBar from '../controls/ToolBar/ToolBar';
import SystemMessage from '../controls/SystemMessage/SystemMessage';
import { Table, TableRow, TableCol } from '../controls/Table/Table';

import { getSummaryErrorsText, ListCmd, BaseCmd, LIST_MODES } from '../../utils/commands';
import FormModel from '../../models/FormModel';

import * as actions from '../../actions/forms';

import './PageForms.scss';

const TOOLBAR_FORMS = [
  {
    name: 'Add form',
    icon: 'plus',
    handleClick: (e, owner) => {
      console.log('Add');
      const formModel = new FormModel();
      owner.props.createForm(new BaseCmd(formModel)).then(r => {
        if (r && r.Code === 0) {
          owner.editForm(r.Data[0].Path);
        }
      });
    }
  },
  {
    type: 'search',
    name: 'Search',
    icon: 'search',
    value: (owner) => {
      return owner.props.forms.filter.Filter || '';
    },
    onSearch: (keyword, owner) => {
      const newFilter = owner.getFilter({ Filter: keyword }).setListMode(LIST_MODES.REPLACE).resetPage();
      owner.props.listForms(newFilter);
    }
  }
];

class PageForms extends AppComponent {
  constructor(props) {
    super(props, 'forms-page');
  }

  getFilter (params) {
    return new ListCmd({ ...this.props.forms.filter, ...params });
  }

  editForm (formPath) {
    this.props.history.push(`/forms/${formPath}/settings`);
  }

  componentDidMount () {
    this.props.listForms(this.getFilter());
  }

  renderToolbar () {
    return (
      <ToolBar buttons={TOOLBAR_FORMS} owner={this} />
    )
  }

  handleClickOpen (e, form) {
    this.props.history.push(`/forms/${form.Path}/submissions`);
  }

  handleClickPublish (e, form) {
    const cmd = new BaseCmd({ Path: form.Path });
    this.props.publishForm(cmd);
  }

  handleClickRemove (e, form) {
    const cmd = new BaseCmd({ Path: form.Path });
    this.props.removeForm(cmd);
  }

  renderPublishStatus (form) {
    let strStatus = 'Not published';

    if (this.props.forms.publishing === form.Path) {
      strStatus = 'Publishing ...';
    } if (this.props.forms.removing === form.Path) {
      strStatus = 'Removing ...';
    } else if (form.Published_On) {
      strStatus = `Published ${form.Pending_Changes ? '(pending changes)' : ''}`;
    }

    return (
      <span>
        {strStatus}
      </span>
    )
  }

  renderNoFormsYet () {
    if (!this.props.forms.filter.Filter) {
      return (
        <div className={this.e('no-forms')}>
          You have no forms yet. To start collection form submissions, please follow the instructions:
          <ul>
            <li>Add a form;</li>
            <li>Configure the form by clicking on the 'Edit' button. If you want to verify collected data then add fields configurations;</li>
            <li>Copy the form URL on the edit form page;</li>
            <li>Use the form URL in the <b>action</b> attribute of a <b>&lt;form&gt;</b> tag on your website;</li>
            <li>That's it. Collect data from your users.</li>
          </ul>
        </div>
      )  
    }

    return (
      <div className={this.e('no-forms')}>
        No forms found
      </div>
    )
  }

  renderActions (f) {
    const { forms } = this.props;

    return (
      <React.Fragment>
        { f.Pending_Changes && f.Published_On && (
          <Button 
            name='Apply changes'
            icon='cloud-upload'
            title='Apply the form pending changes'
            btnStyle='secondary'
            onClick={(e) => this.handleClickPublish(e, f)}
            progress={forms.publishing === f.Path}
          />
        ) }
        { !f.Published_On && (
          <Button 
            name='Publish'
            icon='cloud-upload'
            title='Make the form available for submissions'
            btnStyle='secondary'
            onClick={(e) => this.handleClickPublish(e, f)}
            progress={forms.publishing === f.Path}
          />
        ) }
        <Button
          name='Open'
          icon='eye-open'
          title='View and edit the form'
          btnStyle='primary'
          onClick={(e) => this.handleClickOpen(e, f)}
        />
        <ButtonConfirmation 
          btnStyle='secondary'
          name=''
          icon='trash'
          title='Remove the form'
          onClick={(e) => this.handleClickRemove(e, f)}
          progress={forms.removing === f.Path}
        />
      </React.Fragment>
    )
  }

  renderRow(f) {
    return (
      <TableRow key={`form-${f.Path}`} className={this.e('row')}>
        <TableCol type={['icon', 'top-sm']}>
          <Icon type='list-alt' />
        </TableCol>
        <TableCol type='long-text'>
          <div className={this.e('name')}>
            {f.Name}
          </div>
          <div className={this.e('status')}>
            <span>Submissions: <span>{f.Submissions_Cnt}</span>, </span>
            {this.renderPublishStatus(f)}
          </div>
          <div className={this.e('actions')}>
            {this.renderActions(f)}
          </div>
        </TableCol>
        <TableCol type={['actions', 'hidden-sm']}>
          {this.renderActions(f)}
        </TableCol>
      </TableRow>
    );
  }

  renderFormsList () {
    const { forms } = this.props;

    if (forms.filter.firstPageLoading()) {
      return (<LoadingIndicator/>);
    }

    if (!forms.items.length) {
      return this.renderNoFormsYet();
    }

    return (
      <div className='responsive'>
        <Table>
          {forms.items.map(this.renderRow)}
        </Table>
      </div>
    )
  }

  renderErrors () {
    if (!this.props.forms.errors) {
      return null;
    }

    return (
      <SystemMessage messageType='error'>
        {getSummaryErrorsText({ Errors: this.props.forms.errors })}
      </SystemMessage>
    )
  }

  render () {
    return (
      <AppLayout title={`Forms`} owner={this}>
        {this.renderToolbar()}
        {this.renderErrors()}
        {this.renderFormsList()}
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    forms: state.forms
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageForms));
