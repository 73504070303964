import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';

import * as actions from '../../actions/user';

class PageHome extends AppComponent {
  constructor(props) {
    super(props, 'page-home');
  }

  componentDidMount() {
    this.props.history.push('/forms');
  }

  render() {
    return (
      <EmptyLayout title={`Home`} owner={this}>
        <div className={this.e('container')}>
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageHome));
