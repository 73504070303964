import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import ProfileModel from './../../models/ProfileModel';

import * as actions from '../../actions/account';

class ProfileForm extends AppComponent {
  constructor(props) {
    super(props, 'form-profile');
    this.state = {
      progress: false,
      formFields: new ProfileModel()
    }
  }

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account.profile !== this.props.account.profile) {
      this.setState({ formFields: new ProfileModel(nextProps.account.profile) });
      // this.classMethod();
    }
  }

  handleSubmit (values, onDone) {
    this.setState({ progress: true, formFields: new ProfileModel(values) });
    this.props.saveProfile(new BaseCmd(values)).then((r) => {
      this.setState({ progress: false });
      onDone(r);
    });
  }

  render () {
    return (
      <SimpleForm 
        btnName='Save'
        form={this.state.formFields} 
        onSubmit={this.handleSubmit}
        hideCancel
        progress={this.state.progress}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    account: state.account
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(ProfileForm));
