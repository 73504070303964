import React from 'react';

import AppComponent from '../../AppComponent';
import config from '../../../config/config';

import './AppLogo.scss';

class AppLogo extends AppComponent {
  constructor(props) {
    super(props, 'logo');
  }

  render() {
    return (
      <div className={this.b(this.props.type || '')}>
        <a href={config.website}>
          {config.appName}<sup>Beta</sup>
        </a>
      </div>
    );
  }
}

export default AppLogo;
