
import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class ProfileModel extends BaseModel {
  static getProps() {
    return {
      First_Name: {
        type: mprops.String,
        label: 'First name',
        default: ''
      },
      Middle_Name: {
        type: mprops.String,
        label: 'Middle name',
        default: ''
      },
      Last_Name: {
        type: mprops.String,
        label: 'Last name',
        default: ''
      }
    }
  }
}
