import axios from 'axios';
import config from '../config/config';

export const FIELDS_TYPES = {
  FIELDS_CREATING: 'FIELDS_CREATING',
  FIELDS_LOADING: 'FIELDS_LOADING',
  FIELDS_SET: 'FIELDS_SET',
  FIELDS_SET_LIST: 'FIELDS_SET_LIST',
  FIELDS_ADD: 'FIELDS_ADD',
  FIELDS_SAVING: 'FIELDS_SAVING',
  FIELDS_REMOVING: 'FIELDS_REMOVING',
  FIELDS_REMOVE: 'FIELDS_REMOVE'
};

export const listFields = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: FIELDS_TYPES.FIELDS_LOADING });
    const res = await axios.get(`${config.apiBase}/forms/${formPath}/fields`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: FIELDS_TYPES.FIELDS_SET_LIST, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FIELDS_TYPES.FIELDS_SET_LIST, payload: res.data ? res.data : {}, filter: cmd });
    return res.data ? res.data : null;
  }
}

export const createField = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: FIELDS_TYPES.FIELDS_CREATING });
    const res = await axios.post(`${config.apiBase}/forms/${formPath}/fields`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: FIELDS_TYPES.FIELDS_ADD, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FIELDS_TYPES.FIELDS_ADD, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const updateField = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: FIELDS_TYPES.FIELDS_SAVING, id: cmd.Current_Name });
    const res = await axios.put(`${config.apiBase}/forms/${formPath}/fields/${cmd.Current_Name}`, cmd.toSubmit(), { withCredentials: true });
    dispatch({ type: FIELDS_TYPES.FIELDS_SET, id: cmd.Current_Name, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FIELDS_TYPES.FIELDS_SET, id: cmd.Current_Name, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}

export const removeField = (formPath, cmd) => async dispatch => {
  try {
    dispatch({ type: FIELDS_TYPES.FIELDS_REMOVING, id: cmd.Name });
    const res = await axios.delete(`${config.apiBase}/forms/${formPath}/fields/${cmd.Name}`, { data: cmd.toSubmit() }, { withCredentials: true });
    dispatch({ type: FIELDS_TYPES.FIELDS_REMOVE, id: cmd.Name, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FIELDS_TYPES.FIELDS_REMOVE, id: cmd.Name, payload: res.data ? res.data : {} });
    return res.data ? res.data : null;
  }
}
