import React from 'react';

import AppComponent from '../../AppComponent';

export default class FileSize extends AppComponent {
  constructor(props) {
    super(props, 'file-size');
  }

  formatFileSize() {
    const { size, units, displayUnits } = this.props;

    if (!units) {
      return size;
    }

    let unitsName = 'b';
    let formattedSize = size;
    const precision = 2;

    switch (units.toLowerCase()) {
      default: 
        formattedSize = size;
        break;
      case 'kb':
        formattedSize = +(size / 1024).toFixed(precision);
        unitsName = 'KB';
        break;

      case 'mb':
        formattedSize = +(size / 1048576).toFixed(precision);
        unitsName = 'MB';
        break;
    }

    return displayUnits ? `${formattedSize} ${unitsName}` : formattedSize;
  }

  render() {
    return (
      <div className={this.b()}>
        {this.formatFileSize()}
      </div>
    )
  }
}
