import React from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AppComponent from '../AppComponent';
import EmptyLayout from '../layouts/EmptyLayout';
import SystemMessage from '../controls/SystemMessage/SystemMessage';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import AppLogo from '../controls/AppLogo/AppLogo';

import * as actions from '../../actions/account';

import './PageResetPassword.scss';

class PageResetPassword extends AppComponent {
  constructor(props) {
    super(props, 'page-reset-password');
    this.state = {
      requestSent: false,
      token: '',
    }
  }

  componentDidMount() {
    const search = queryString.parse(this.props.location.search);
    this.setState({ token: search.token });
  }

  handleSubmitted (result) {
    this.setState({ requestSent: true });
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Reset Password</h1>
      </div>
    )
  }

  renderPasswordReset() {
    return (
      <div className={this.e('intro')}>
        Your password has been reset. Please go to the 'Sign In' page to authorize.
      </div>
    )
  }

  renderIntro() {
    return (
      <div className={this.e('intro')}>
        Please enter your login and new password.
      </div>
    )
  }

  renderNoToken() {
    if (this.state.token) {
      return null;
    }

    return (
      <SystemMessage messageType='error'>
        The link to reset your password is incorrect. Please request it one more time.
      </SystemMessage>
    )
  }

  renderForm() {
    if (!this.state.token) {
      return this.renderNoToken();
    }

    return (
      <div>
        {this.renderIntro()}
        <div className={this.e('form-container')}>
          <ResetPasswordForm
            token={this.state.token}
            onSubmitted={this.handleSubmitted}
          />
        </div>
      </div>
    )
  }

  renderAccountLinks() {
    return (
      <div className={this.e('account-links')}>
        <Link className='nav-link' to='/signin'>Sign In</Link>
        <span>&nbsp;|&nbsp;</span>
        <Link className='nav-link' to='/signup'>Sign Up.</Link>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Forgot Password`} owner={this}>
        <div className={this.e('container')}>
          { this.renderLogo() }
          { this.state.requestSent && this.renderPasswordReset() }
          { !this.state.requestSent && this.renderForm() }
          {this.renderAccountLinks()}
        </div>
      </EmptyLayout>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(PageResetPassword));
