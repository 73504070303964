import axios from 'axios';
import config from '../config/config';

export const FILES_TYPES = {
  FILES_LIST: 'FILES_LOADING',
  FILES_REMOVE: 'FILES_REMOVE'
};

export const clearFilesList = () =>  dispatch => {
  dispatch({ type: FILES_TYPES.FILES_LIST, id: '', payload: { Data: [] }, progress: false });
}

export const listSubmissionFiles = (formPath, submissionId, cmd) => async dispatch => {
  try {
    dispatch({ type: FILES_TYPES.FILES_LIST, id: submissionId, progress: true });
    const res = await axios.get(`${config.apiBase}/forms/${formPath}/submissions/${submissionId}/files`, { params: cmd.toSubmit(), withCredentials: true });
    dispatch({ type: FILES_TYPES.FILES_LIST, id: submissionId, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FILES_TYPES.FILES_LIST, id: submissionId, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  }
}

export const removeSubmissionFile = (formPath, submissionId, fileId) => async dispatch => {
  try {
    dispatch({ type: FILES_TYPES.FILES_REMOVE, id: fileId, progress: true });
    const res = await axios.delete(`${config.apiBase}/forms/${formPath}/submissions/${submissionId}/files/${fileId}`, { data: {} }, { withCredentials: true });
    dispatch({ type: FILES_TYPES.FILES_REMOVE, id: fileId, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: FILES_TYPES.FILES_REMOVE, id: fileId, payload: res.data ? res.data : {}, progress: false });
    return res.data ? res.data : null;
  }
}
