
import BaseModel, { ModelPropsTypes as mprops }  from './BaseModel';

export default class SignInModel extends BaseModel {
  static getProps() {
    return {
      User_Login: {
        type: mprops.String,
        label: 'Login',
        default: ''
      },
      Email: {
        type: mprops.String,
        label: 'Email',
        default: ''
      },
      Password: {
        type: mprops.String,
        password: true,
        label: 'Password',
        default: ''
      },
      Repeat_Password: {
        type: mprops.String,
        password: true,
        label: 'Repeat password',
        default: ''
      },
      Plan_ID: {
        type: mprops.Select,
        label: 'Selected plan',
        disableEdit: true,
      }
    }
  }
}
