import { USER_TYPES } from '../actions/user';

export default function(state = null, action) {

    if (state == null) {
        state = {
            loading: true,
            auth: null,
            profile: null
        }
    }

	switch (action.type)
	{
		case USER_TYPES.USER_CHECKING:
            return {...state, loading: true };
            
        case USER_TYPES.USER_CHECKED:
			return {...state, loading: false, auth: action.payload.Data[0] };

		default:
			return state;
	}
}