import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import ForgotPasswordModel from './../../models/ForgotPassword';

import * as actions from '../../actions/account';

class ForgotPasswordForm extends AppComponent {
  constructor(props) {
    super(props, 'form-signin');
    this.state = {
      progress: false,
      formFields: new ForgotPasswordModel()
    }
  }

  handleSubmit (values, onDone) {
    this.setState({ progress: true, formFields: new ForgotPasswordModel(values) });
    this.props.requestPasswordReset(new BaseCmd(values)).then((r) => {
      this.setState({ progress: false });
      if (r && r.Code === 0) {
        r.Message = 'An email with reset password link has been sent to your email address';
        if (typeof this.props.onSubmitted === 'function') {
          this.props.onSubmitted(r);
        }
      }
      onDone(r);
    });
  }

  render () {
    return (
      <SimpleForm 
        btnName='Request reset link'
        form={this.state.formFields} 
        onSubmit={this.handleSubmit}
        hideCancel
        progress={this.state.progress}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(ForgotPasswordForm));
