import React from 'react';

import AppComponent from '../../AppComponent';

import './SelectInput.scss';

class SelectInput extends AppComponent {
  constructor(props) {
    super(props, 'fi');

    this.state = {
      value: (props && props.value) || '',
      options: (props && props.options) || []
    }
  }

  onGotOptions(options) {
    this.setState({ options });
  }

  componentDidMount () {
    if (typeof this.props.getOptionsAsync === 'function') {
      this.props.getOptionsAsync(this.onGotOptions)
    }
  }

  componentWillReceiveProps(nextProps){
    const obj = {};

    if (nextProps.value !== this.props.value){
      obj['value'] = nextProps.value || '';
    }

    if (nextProps.options !== this.props.options){
      obj['options'] = this.props.options;
    }

    this.setState(obj);
    // this.classMethod();
  }

  handleChange(e, name) {
    let value = e.target.value;
    this.setState({ value });
    if (typeof this.props.onChange  === 'function') {
      this.props.onChange(e, name, value);
    }
  }

  render() {
    const options = this.props.children || this.state.options;

    const items = options.map((o, idx) => {
      return (
        <option key={`select-${this.props.name}-${o.value}-${idx}`} value={o.value}>{o.text}</option>
      )
    });

    return (
      <select
        name={this.props.name} 
        onChange={(e) => { this.handleChange(e, this.props.name) }}  
        value={this.state.value}
      >
        {items}
      </select>
    );
  }
}

export default SelectInput;
