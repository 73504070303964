import React from 'react';
import { CSSTransition } from "react-css-transition";

import AppComponent from '../../AppComponent';
import Button from '../Button/Button';
import SystemMessage from '../SystemMessage/SystemMessage';
import FormInput from '../FormInput/FormInput';
import { ModelPropsTypes as mprops }  from '../../../models/BaseModel';

import { getSummaryErrorsText, getFieldErrors } from '../../../utils/commands'

import './SimpleForm.scss';

class SimpleForm extends AppComponent {
  constructor(props) {
    super(props, 'sf');

    this.state = {
      fields: props.form.constructor.getProps(),
      values: props.form.getValues(),
      progress: false,
      result: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps: ', nextProps);
    const newVals = nextProps.form && nextProps.form.getValues();
    if (newVals !== this.state.values) {
      this.setState({ values: newVals });
      // this.classMethod();
    }
  }

  handleChange(e, name, value) {
    const field = this.state.fields[name];

    if (field.type === mprops.Number) {
      value = +value;
    } else if (field.type === mprops.Bool) {
      value = !!value;
    }

    this.setState({ values: {...this.state.values, [name]: value } });
    if (typeof this.props.onFormChange === 'function') {
      this.props.onFormChange(name, value);
    }
  }

  handleOnSubmitDone (r) {
    const newState = {
      result: {},
      progress: false
    };

    if (r) {
      if (r.Code === 0) {
        // TODO: Clear form values to its defaults
      } else {
        newState.result = r;
      }
    }

    this.setState(newState);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ result: {}, progress: true });
      this.props.onSubmit(this.state.values, this.handleOnSubmitDone);
    }
  }

  handleClickCancel(e) {
    e.preventDefault();
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  }

  renderSummaryError () {
    if (!this.state.result || !this.state.result.Code) {
      return null;
    }

    const summaryError = getSummaryErrorsText(this.state.result);

    return summaryError && (
      <SystemMessage
        messageType='error'
        message={summaryError}
      />
    );
  }

  renderInputs() {
    const props = this.state.fields;
    const inputs = Object.keys(props).map((key, idx) => {
      const field = props[key];
      if (field.disableEdit === true) {
        return null;
      }
      if (typeof field.visible === 'function') {
        if (!field.visible(this.state.values)) {
          return null;
        }
      }
      return (
        <div key={`${key}-${idx}`} className={this.e('inputs-row')}>
          <div className={this.e('input-control', 'full')}>
            <FormInput
              name={key}
              field={field}
              onChange={this.handleChange}
              value={this.state.values[key]}
              error={getFieldErrors(this.state.result, `${key}`)}
            />
          </div>
        </div>
      );
    }).filter((i) => { return i != null });

    return (
      <div>
        {inputs}
      </div>
    )
  }

  renderButtons() {
    return (
      <div className={this.e('submit')}>
        <Button 
          type='submit'
          btnStyle='primary'
          btnSize={ this.props.btnName ? 'small' : 'action' }
          icon={this.props.btnIcon || 'ok'}
          progress={this.state.progress}
        >
          { this.props.btnName && (<span>{this.props.btnName}</span>)}
        </Button>
        { !this.props.hideCancel && (
          <Button btnStyle='secondary' btnSize={ this.props.btnName ? 'small' : 'action' } icon={'ban-circle'} onClick={this.handleClickCancel}>
          { this.props.btnName && (<span>Cancel</span>)}
        </Button>
        )}
        { this.props.renderExtraButtons && (
          <div className={this.e('extra-buttons')}>
            {this.props.renderExtraButtons()}
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <CSSTransition
        transitionAppear
        defaultClassName={this.e('default')}
        enterClassName={this.e('enter')}
        leaveClassName={this.e('leave')}
        activeClassName={this.e('active')}
        active
      >
        <div className={this.b()}>
          <form onSubmit={this.handleSubmit}>
            <fieldset disabled={this.props.disabled}>
              {this.renderInputs()}
              {this.renderSummaryError()}
              {this.renderButtons()}
            </fieldset>
          </form>
        </div>
      </CSSTransition>
    );
  }
}

export default SimpleForm;
