import React from 'react';
import { connect } from 'react-redux';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import FormModel from './../../models/FormModel';

import * as actions from '../../actions/forms';

class FormEditForm extends AppComponent {
  constructor(props) {
    super(props, 'form-edit');
  }

  handleSubmit (values, onDone) {
    this.subscribeCallback('updateForm', onDone);
    this.props.updateForm(new BaseCmd(values)).then((r) => {
      if (typeof this.props.onSubmitted === 'function') {
        this.props.onSubmitted(r && r.Data && r.Data[0]);
      }
      this.callCallback('updateForm', r);
    });
  }

  render () {
    const formFields = new FormModel(this.props.forms.edit);

    if (!formFields) {
      return null;
    }

    return (
      <SimpleForm 
        btnName='Save'
        form={formFields} 
        onSubmit={this.handleSubmit}
        hideCancel
      />
    );
  }
}

function mapStateToProps(state) {
  const props = {
    forms: state.forms,
  };
  return props;
}

export default connect(mapStateToProps, actions)(FormEditForm);
