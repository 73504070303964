import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import UpdatePassword from './../../models/UpdatePassword';

import * as actions from '../../actions/account';

class UpdatePasswordForm extends AppComponent {
  constructor(props) {
    super(props, 'form-update-password');
    this.state = {
      formFields: new UpdatePassword()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.auth && nextProps.user.auth.User_Login !== this.state.formFields.User_Login) {
      this.setState({ formFields: new UpdatePassword({ User_Login: nextProps.user.auth.User_Login }) });
    }
  }

  handleSubmit (values, onDone) {
    this.setState({ formFields: new UpdatePassword(values) });
    this.props.updatePasswod(new BaseCmd({ ...values })).then((r) => {
      onDone(r);
    });
  }

  render () {
    return (
      <SimpleForm 
        btnName='Update password'
        form={this.state.formFields} 
        onSubmit={this.handleSubmit}
        hideCancel
        progress={this.props.account.savingPwd}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user,
    account: state.account
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(UpdatePasswordForm));
