import React from 'react';
import _ from 'lodash';

import AppComponent from '../../AppComponent';
import Icon from '../Icon/Icon';

import './Button.scss';

class Button extends AppComponent {
  constructor(props) {
    super(props, 'button')
  }

  render() {
    const btnProps = _.omit(this.props, ['name', 'btnStyle', 'btnSize', 'checked', 'progress', 'className']);
    const checkedCss = this.props.checked ? 'checked' : '';
    const progress = this.props.progress !== undefined ? this.props.progress : false;
    const noMargin = !this.props.children && !this.props.name;
    const className = this.props.className || '';

    return (
      <button 
        className={`btn ${this.props.btnStyle || ''}
        ${this.props.btnSize || ''} 
        ${checkedCss}
        ${className}`}
        {...btnProps}
      >
        {this.props.icon && !progress && (
          <Icon type={this.props.icon} noMargin={noMargin} />
        )}
        { progress && (
          <Icon type='refresh' noMargin={noMargin} />
        )}
        {this.props.children ? this.props.children : this.props.name}
      </button>
    );
  }
}

export default Button;
