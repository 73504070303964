export const loadScript = (url, async, onload) => {
  var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = async !== undefined ? async : false; wa.src = url; wa.onload = onload;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wa, s);
  //s.append(wa);        
}

export const loadStyle = (url, async, onload) => {
  const links = document.getElementsByTagName('link');
  for (let i = 0, l = links.length; i < l; ++i) { if (links[i].href === url) { return; } }
  var wa = document.createElement('link'); wa.rel = 'stylesheet'; wa.type = 'text/css'; wa.async = async !== undefined ? async : false; wa.href = url; wa.onload = onload;
  var s = document.getElementsByTagName('head')[0];
  //s.parentNode.insertBefore(wa, s);
  s.appendChild(wa);
}
