import React from 'react'
import { Link, withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import AppLogo from '../controls/AppLogo/AppLogo';
import EmptyLayout from '../layouts/EmptyLayout';

import './PageNotFound.scss';

class PageNotFound extends AppComponent {
  constructor(props) {
    super(props, 'page-not-found');
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Page Not Found</h1>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Good bye`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          <div>
            <p>Page you are looking for was not found.</p>
            <p><Link to='/forms'>Go to the 'Forms' page.</Link></p>
          </div>
        </div>
      </EmptyLayout>
    )
  }
}

export default withRouter(PageNotFound);
