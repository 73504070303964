import React from 'react'
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import AppLogo from '../controls/AppLogo/AppLogo';
import EmptyLayout from '../layouts/EmptyLayout';

import './PageGoodBye.scss';

class PageGoodBye extends AppComponent {
  constructor(props) {
    super(props, 'page-good-bye');
  }

  componentDidMount() {
    // TODO: Load forms list
  }

  renderLogo() {
    return (
      <div className={this.e('logo-container')}>
        <AppLogo type='dark' />
        <h1>Good bye</h1>
      </div>
    )
  }

  render() {
    return (
      <EmptyLayout title={`Good bye`} owner={this}>
        <div className={this.e('container')}>
          {this.renderLogo()}
          <div>
            <p>Thank you for trying our service.</p>
            <p>We would really appreciate if you could tell us the reason why you stopped using our service.</p>
            <p>Email: support@formsubmits.com</p>
          </div>
        </div>
      </EmptyLayout>
    )
  }
}

export default withRouter(PageGoodBye);
