import React from 'react';

import AppComponent from '../../AppComponent';
import { ModelPropsTypes } from '../../../models/BaseModel';
import SelectInput from '../SelectInput/SelectInput';

import './FormInput.scss';

class FormInput extends AppComponent {
  constructor(props) {
    super(props, 'fi');

    this.state = {
      value: (props && props.value) || '',
    }

    this.inputMap = {
      [ModelPropsTypes.Date]: this.renderTextInput,
      [ModelPropsTypes.Time]: this.renderTextInput,
      [ModelPropsTypes.DateTime]: this.renderTextInput,
      [ModelPropsTypes.String]: this.renderTextInput,
      [ModelPropsTypes.RichText]: this.renderTextArea,
      [ModelPropsTypes.Number]: this.renderNumberInput,
      [ModelPropsTypes.Bool]: this.renderCheckBox,
      [ModelPropsTypes.Select]: this.renderSelect,
    }
  }

  // componentDidMount () {
  //   this.setState({ value: this.props.value || '' });
  // }

  componentWillReceiveProps(nextProps){
    if(nextProps.value !== this.props.value){
      this.setState({ value: nextProps.value || '' });
      // this.classMethod();
    }
  }

  handleChange(e, name, field) {
    let value = e.target.value;
    this.setState({ value });
    if (typeof this.props.onChange  === 'function') {
      this.props.onChange(e, name, e.target.value);
    }
  }

  handleChangeCheckbox(e, name) {
    this.setState({ value: e.target.checked });
    if (typeof this.props.onChange  === 'function') {
      this.props.onChange(e, name, e.target.checked);
    }
  }

  renderLabel(field, name) {
    if (!field.label) {
      return null;
    }
    return (
      <label htmlFor={name} className={field.required ? 'required' : ''}>{field.label}</label>
    );
  }

  renderHint(field, name) {
    return field.hint && (<div className={this.e('hint')} dangerouslySetInnerHTML={{ __html: field.hint }}></div>)
  }

  renderError(field, name) {
    return this.props.error && (
      this.props.error.map((e, idx) => {
        return (
          <div key={`error-${name}-${idx}`} className={this.e('error')}>
            {e}
          </div>
        );
      })
    )
  }

  renderInput(name, type, field, props) {
    let inputProps = {};

    switch (type) {
      default:
      case 'text':
        if (field.maxlength !== undefined) {
          inputProps['maxlength'] = field.maxlength;
        }
        if (field.password === true) {
          type = 'password';
        }
        break;

      case 'number':
        if (field.max !== undefined) {
          inputProps['max'] = field.max;
        }
        if (field.min !== undefined) {
          inputProps['min'] = field.min;
        }
        break;
    }

    return (
      <div>
        {this.renderLabel(field, name)}
        <input
          name={name} 
          type={type}
          onChange={(e) => { this.handleChange(e, name, field) }}
          value={this.state.value}
          placeholder={field.placeholder || ''}
          {...inputProps}
        />
        {this.renderHint(field, name)}
        {this.renderError(field, name)}
      </div>
    );
  }

  renderTextInput(name, field, props) {
    return this.renderInput(name, 'text', field, props);
  }

  renderNumberInput(name, field, props) {
    return this.renderInput(name, 'number', field, props);
  }

  renderTextArea(name, field, props) {
    // console.log('renderTextArea: ', name, field, this.state.value)
    return (
      <div>
        {this.renderLabel(field, name)}
        <textarea
          name={name}
          onChange={(e) => { this.handleChange(e, name, field) }}  
          value={this.state.value}
        >
        </textarea>
        {this.renderHint(field, name)}
        {this.renderError(field, name)}
      </div>
    );
  }

  renderCheckBox(name, field, props) {
    return (
      <div className={this.e('cbk-input')}>
        <label>
          <input
            name={name} 
            type='checkbox'
            onChange={(e) => { this.handleChangeCheckbox(e, name, field) }} 
            checked={this.state.value}
          />
          <span></span>
          <span>{field.label}</span>
        </label>
        {this.renderHint(field, name)}
        {this.renderError(field, name)}
      </div>
    );
  }

  renderSelect(name, field, props) {
    return (
      <div>
        {this.renderLabel(field, name)}
        <SelectInput
          name={name}
          value={this.state.value}
          options={field.options}
          onChange={(e) => { this.handleChange(e, name, field) }}
        />
        {this.renderHint(field, name)}
        {this.renderError(field, name)}
      </div>
    );
  }

  renderRadio(prop) {
    
  }

  render() {
    const { field } = this.props;

    if (!field) {
      return null;
    }

    const fn = this.inputMap[field.type]

    if (!fn) {
      return null;
    }

    return (
      <div className={this.b()}>
        {fn(this.props.name, field, this.props)}
      </div>
    );
  }
}

export default FormInput;
