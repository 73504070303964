import axios from 'axios';

export const LIST_MODES = {
  ADD_BEFORE: 'LIST_MODE_ADD_BEFORE',
  ADD_AFTER: 'LIST_MODE_ADD_AFTER',
  REPLACE: 'LIST_MODE_REPLACE'
}

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  // Do something with response error
  // if (error.response.status === 401) {
  //     console.log('unauthorized, logging out ...');
  //     auth.logout();
  //     router.replace('/auth/login');
  // }
  return Promise.reject(error.response);
});

export const getFieldErrors = (result, fieldName) => {
  const r = (result && result.Errors && result.Errors[fieldName]) || (result && result.errors && result.errors[fieldName]) || {};
  return r.errors;
}

export const getSummaryErrorsText = (result) => {
  const r = (result && result.Errors && result.Errors['']) || (result && result.errors && result.errors['']) ||{};
  return (r.errors && r.errors[0]) || '';
}

export class BaseCmd {
  constructor(props) {
    Object.assign(this, props);
  }

  getExcludeFields() {
    return [];
  }

  toSubmit() {
    const keys = Object.keys(this);
    const obj = {}
    const exclude = this.getExcludeFields();

    for (var i = 0, l = keys.length; i < l; ++i) {
      if (exclude.indexOf(keys[i]) !== -1) {
        continue;
      }

      if (typeof this[keys[i]] === 'string' && !this[keys[i]]) {
        obj[keys[i]] = null;
      } else {
        obj[keys[i]] = this[keys[i]];
      }
    }

    return obj;
  }
}

export class WithItemCmd extends BaseCmd {
  constructor(props, item) {
    super(props)
    this.Item = item;
  }
}

export class ListCmd extends BaseCmd {
  constructor(props) {
    super(props);

    this.Page = (props && props.Page) || 1;
    this.PageSize = (props && props.PageSize) || 50;
    this.OrderBy = (props && props.OrderBy) || '';
    this.Filter = (props && props.Filter) || '';

    this.loading = props && props.loading != null ? props.loading : true;
    this.loadedCnt = (props && props.loadedCnt) || 0;
    this.listMode = (props && props.listMode) || LIST_MODES.REPLACE;
    this.items = (props && props.items) || [];
  }

  getExcludeFields() {
    return ['loading', 'loadedCnt', 'listMode', 'items'];
  }

  setListMode(mode) {
    this.listMode = mode;
    return this;
  }

  reset() {
    this.Page = 1;
    this.Filter = '';
    return this;
  }

  resetPage() {
    this.Page = 1;
    return this;
  }

  prevPage() {
    this.Page--;
    return this;
  }

  nextPage() {
    this.Page++;
    return this;
  }

  startLoading() {
    this.loading = true;
    return this;
  }

  setLoadedCnt(cnt) {
    this.loadedCnt = cnt;
    this.loading = false;
    return this;
  }

  setOrderBy(orderBy) {
    this.Order_By = orderBy;
    return this;
  }

  firstPageLoading() {
    return this.loading && this.Page === 1;
  }

  canLoadNext() {
    return this.loadedCnt >= this.PageSize;
  }

  canLoadPrev() {
    return this.loadedCnt >= this.PageSize;
  }
}