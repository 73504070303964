import { SUBMISSIONS_TYPES } from '../actions/submissions';
import { LIST_MODES, ListCmd } from '../utils/commands';

export default function(state = null, action) {
  if (state == null) {
    state = {
      loading: true,
      downloadingId: '',
      items: [],
      filter: new ListCmd(),
      errors: null,
      edit: null
    }
  }

  switch (action.type)
  {
    case SUBMISSIONS_TYPES.SUBMISSIONS_LOADING_STATS: {
      const newFilter = new ListCmd(action.filter).startLoading();
      return {
        ...state,
        loading: true,
        filter: newFilter,
      };
    }
          
    case SUBMISSIONS_TYPES.SUBMISSIONS_SET_STATS: {
      let newItems = action.payload.Data;

      const newFilter = new ListCmd(action.filter).setLoadedCnt(newItems.length);
    
      switch (newFilter.listMode) {
        default: break;
        case LIST_MODES.ADD_AFTER: newItems = [...state.items, ...newItems]; break;
        case LIST_MODES.ADD_BEFORE: newItems = [...newItems, ...state.items]; break;
        case LIST_MODES.REPLACE: newItems = [...newItems]; break;
      }

      return {
        ...state,
        loading: false,
        items: newItems,
        filter: newFilter,
        errors: action.payload && action.payload.Errors
      };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_STATS_RESET: {
      const newItems = action.progress ? [...state.items] : [
        ...state.items.map((f) => { 
          if (f.ID === action.id) {
            const reduceTo = (action.payload && action.payload.Data && action.payload.Data[0]) || 0;
            f.Submissions_Cnt -= +reduceTo;
            if (f.Submissions_Cnt < 0) {
              f.Submissions_Cnt = 0;
            }
          }
          return f;
        })
      ];

      return {
        ...state, 
        resetingId: action.progress ? action.id : '',
        items: newItems
      };
    }

    case SUBMISSIONS_TYPES.SUBMISSIONS_STATS_DOWNLOAD: {
      return {
        ...state,
        downloadingId: action.progress ? action.id : ''
      }
    }

    default:
      return state;
  }
}
