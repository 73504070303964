import axios from 'axios';
import config from '../config/config';

export const USER_TYPES = {
  USER_CHECKING: 'USER_CHECKING',
  USER_CHECKED: 'USER_CHECKED',
  USER_SIGING_IN: 'USER_SIGING_IN',
  USER_SIGNED_IN: 'USER_SIGNED_IN',
  USER_SIGING_UP: 'USER_SIGING_UP',
  USER_SIGNED_UP: 'USER_SIGNED_UP',
  USER_SIGING_OUT: 'USER_SIGING_OUT',
  USER_SIGNED_OUT: 'USER_SIGNED_OUT',
  USER_REGISTERING: 'USER_REGISTERING',
  USER_REGISTERED: 'USER_REGISTERED',
};

axios.defaults.withCredentials = true;

// axios.interceptors.request.use(request => {
//   console.log('Starting Request', request)
//   return request
// })

export const checkUser = () => async dispatch => {
  try {
    dispatch({ type: USER_TYPES.USER_CHECKING });
    const res = await axios.get(`${config.apiBase}/auth/currentuser`, { params: {}, withCredentials: true });
    dispatch({ type: USER_TYPES.USER_CHECKED, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: USER_TYPES.USER_CHECKED, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const signIn = (cmd) => async dispatch => {
  try {
    dispatch({ type: USER_TYPES.USER_SIGING_IN });
    const res = await axios.post(`/auth/signin`, cmd.toSubmit());
    dispatch({ type: USER_TYPES.USER_SIGNED_IN, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: USER_TYPES.USER_SIGNED_IN, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const signUp = (cmd) => async dispatch => {
  try {
    dispatch({ type: USER_TYPES.USER_SIGING_UP });
    const res = await axios.post(`${config.apiBase}/account/register`, cmd.toSubmit());
    dispatch({ type: USER_TYPES.USER_SIGNED_UP, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: USER_TYPES.USER_SIGNED_UP, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}

export const signOut = () => async dispatch => {
  try {
    dispatch({ type: USER_TYPES.USER_SIGING_OUT });
    const res = await axios.post(`/auth/signout`);
    dispatch({ type: USER_TYPES.USER_SIGING_OUT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  } catch (res) {
    dispatch({ type: USER_TYPES.USER_SIGING_OUT, payload: res.data ? res.data : null });
    return res.data ? res.data : null;
  }
}
