import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import SignUpModel from './../../models/SignUpModel';

import * as actions from '../../actions/user';

class SignUpForm extends AppComponent {
  constructor(props) {
    super(props, 'form-signin');

    this.state = {
      progress: false,
      formFields: new SignUpModel()
    }
  }

  handleSignUp (values, onDone) {
    this.setState({ progress: true, formFields: new SignUpModel(values) });
    this.subscribeCallback('signup', onDone);
    this.props.signUp(new BaseCmd(values)).then((r) => {
      if (r && r.Code === 0) {
        this.props.history.push('/forms');
      }
      this.callCallback('signup', r);
    });
  }

  render () {
    return (
      <SimpleForm 
        btnName='Sign Up'
        form={this.state.formFields} 
        onSubmit={this.handleSignUp}
        hideCancel
        progress={this.state.progress}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(SignUpForm));
