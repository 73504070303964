import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions/user';
import AppComponent from '../AppComponent';
import Header from '../controls/Header/Header';
import AppFooter from '../controls/AppFooter/AppFooter';

// import 'normalize.css';
import '../../styles/glyphicons.css';
import '../../styles/index.scss';
import './AppLayout.scss';

class AppLayout extends AppComponent {
  constructor(props) {
    super(props, 'app-layout')
  }

  componentDidMount() {
    this.props.checkUser().then(r => {
      if (!r || !r.Data || !r.Data[0] || !r.Data[0].Is_Authorized) {
        this.props.history.push('/signin');
      }
    })
  }

  render() {
    return (
      <div className={this.b()}>
        <Header />
        <div className={this.e('container')}>
          { this.props.title && (
            <h1>{this.props.title}</h1>
          )}
          { this.props.description && (
            <div className={this.e('description')}>
            </div>
          )}
          <div className={this.e('content')}>
            {this.props.children}
          </div>
        </div>
        <AppFooter/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(AppLayout));
