import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppComponent from '../AppComponent';
import SimpleForm from '../controls/SimpleForm/SimpleForm';
import { BaseCmd } from '../../utils/commands';
import SignInModel from './../../models/SignInModel';

import * as actions from '../../actions/user';

class SignInForm extends AppComponent {
  constructor(props) {
    super(props, 'form-signin');
    this.state = {
      progress: false,
      formFields: new SignInModel()
    }
  }

  handleSignIn (values, onDone) {
    this.setState({ progress: true, formFields: new SignInModel(values) });
    this.subscribeCallback('sigin', onDone);
    this.props.signIn(new BaseCmd(values)).then((r) => {
      this.setState({ progress: false });
      if (r && r.Code === 0) {
        this.props.history.push('/forms');
      }
      this.callCallback('sigin', r);
    });
  }

  render () {
    return (
      <SimpleForm 
        btnName='Sign In'
        form={this.state.formFields} 
        onSubmit={this.handleSignIn}
        hideCancel
        progress={this.state.progress}
      />
    )
  }
}

function mapStateToProps(state) {
  const props = {
    user: state.user
  };
  return props;
}

export default connect(mapStateToProps, actions)(withRouter(SignInForm));
